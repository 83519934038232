import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CustomAlert = ({ message, show, onClose }) => {
    return (
        <Modal show={show} onHide={onClose} top className='SuccesModal' backdrop="static">

            <div className="succesheader">
                Success
            </div>
            <div className="line"></div>
            <Modal.Body >
                <div className='successmessge'>
                    <p>{message}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="button-save" variant="outline-success" onClick={onClose}>
                    OK
                </Button>

            </Modal.Footer>
        </Modal>
    );
};

export default CustomAlert;
