// LoginForm.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "./logo.png";
import CustomAlert from "../CustomAlert/CustomAlert";
import { urlApi } from "../../App";

function LoginPage({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertCallback, setAlertCallback] = useState(null);
  const [autoDismiss, setAutoDismiss] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const toggleEyeIcon = () => {
    setshowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${urlApi}/login`, {
        user_id: username,
        password,
      });

      if (response.data && response.data.success) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("expiresAt", response.data.expiresAt); 
        localStorage.setItem("signImg", response.data.signImg);
        localStorage.setItem("profImg", response.data.profileImg);
        localStorage.setItem("userid", response.data.id);

        onLogin({
          userId: response.data.userId,
          userInput: username,
          userPassword: password,
        });
        showAlertWithAutoDismiss("Login Successfully");
      } else {
        showAlertWithAutoDismiss(
          response.data.message ||
            "Access denied. Please check your credentials."
        );
      }
    } catch (error) {
      if (error.response) {
        showAlertWithAutoDismiss(`${error.response.data.message}`);
      } else if (error.request) {
      } else {
        showAlertWithAutoDismiss(`Error: ${error.message}`);
      }
    }
  };
  const showAlertMessage = (message, callback = null, dismissAfter = null) => {
    setAlertMessage(message);
    setAlertCallback(callback);
    setShowAlert(true);
    if (dismissAfter) {
      setTimeout(() => {
        handleAlertClose();
      }, dismissAfter);
    }
  };
  const showAlertWithAutoDismiss = (message) => {
    showAlertMessage(message, null, 2000);
  };
  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    if (alertCallback) {
      alertCallback();
    }
    setAutoDismiss(false);
  };
  return (
    <div className="loginouter">
      <div id="login_complete">
        <div className="logininner">
          <div className="login_headandimg">
            <div className="login_logo">
              <img src={logo} alt="" />
            </div>

            <div className="login_heading">
              <h1>SWIFT PASS</h1>
            </div>
          </div>
          <CustomAlert
            message={alertMessage}
            show={showAlert}
            onClose={handleAlertClose}
          />
          <form id="login_fields" onSubmit={handleLogin}>
            <div className="login_fields">
              <div className="input_fields">
                <div className="input-icon">
                  <i class="fa-solid fa-user"></i>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Enter Your Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="input_fields">
                <div className="input-icon">
                  <i className="fa-solid fa-lock"></i>
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i
                  onClick={toggleEyeIcon}
                  className={
                    !showPassword
                      ? "fa-regular fa-eye-slash"
                      : "fa-regular fa-eye"
                  }
                ></i>
              </div>
            </div>

            <div class="login_button">
              <button type="submit" className="bttn">
                Login
                <i className="fa-solid fa-right-to-bracket"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
