// DistrictQuota.jsx
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Table,
  Form,
  CloseButton,
  Pagination,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FaEdit, FaPlus } from "react-icons/fa";
import axios from "axios";
import CustomAlert from "../CustomAlert/CustomAlert";
import "bootstrap/dist/css/bootstrap.min.css";
import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";
import { urlApi } from "../../App";
const formatCurrency = (value) => {
  const cleanedValue = value.replace(/[^\d.]/g, "");
  const [integerPart, decimalPart] = cleanedValue.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return decimalPart !== undefined
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
};

const DistrictQuota = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [districtQuotas, setDistrictQuotas] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [financialYear, setFinancialYear] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [code, setCode] = useState("");
  const [lines, setLines] = useState([
    { lineindex: "", districtid: "", quota: "" },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertCallback, setAlertCallback] = useState(null);
  const [autoDismiss, setAutoDismiss] = useState(false);
  const indexOfLastEntry = currentPage * 10;
  const indexOfFirstEntry = indexOfLastEntry - 10;
  const totalPages = Math.ceil(districtQuotas.length / 10);
  const currentEntries = districtQuotas.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [codeError, setCodeError] = useState("");
  const [financialYearError, setFinancialYearError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const districtQuotaResponse = await axios.get(
        `${urlApi}/api/v1/districtquotas`
      );
      const formattedDistrictQuotas = districtQuotaResponse.data.map(
        (element) => {
          let startdate = new Date(element.startdate);
          let enddate = new Date(element.enddate);

          // Adjust for timezone offset
          startdate = new Date(startdate.getTime() + Math.abs(startdate.getTimezoneOffset() * 60000));
          enddate = new Date(enddate.getTime() + Math.abs(enddate.getTimezoneOffset() * 60000));

          return {
            ...element,
            startdate: startdate.toISOString().split("T")[0],
            enddate: enddate.toISOString().split("T")[0],
          };
        }
      );
      setDistrictQuotas(formattedDistrictQuotas);
    
      const districtsResponse = await axios.get(`${urlApi}/api/v1/districts`);
      setDistricts(districtsResponse.data);

      // setStartDate(currentDate);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const districtQuotaResponse = await axios.get(
  //       `${urlApi}/api/v1/districtquotas`
  //     );
  //     const formattedDistrictQuotas = districtQuotaResponse.data.map(
  //       (element) => ({
  //         ...element,
  //         startdate: new Date(element.startdate).toISOString().split("T")[0], //
  //         enddate: new Date(element.enddate).toISOString().split("T")[0], //
  //       })
  //     );
  //     setDistrictQuotas(formattedDistrictQuotas);

  //     const districtsResponse = await axios.get(`${urlApi}/api/v1/districts`);
  //     setDistricts(districtsResponse.data);

  //     setStartDate(currentDate);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const addDataToTable = async () => {
    if (!validateForm()) {
      return;
    }

    const currentUser = localStorage.getItem("userData");

    const newData = {
      code,
      financialyear: financialYear,
      startdate: startDate,
      startdate: startDate,
      enddate: endDate,
      adduser: currentUser,
      adddatetime: new Date().toISOString(),
      edituser: selectedIndex !== null ? currentUser : null,
      editdatetime: selectedIndex !== null ? new Date().toISOString() : null,
      lines: lines.map((line, index) => ({
        lineindex: index + 1,
        quota: line.quota || 0,
        districtid: line.districtid,
      })),
    };

    try {
      if (selectedIndex !== null) {
        await axios.put(
          `${urlApi}/api/v1/districtquotas/${districtQuotas[selectedIndex].id}`,
          newData
        );
        showAlertWithAutoDismiss("Record updated successfully.");
      } else {
        await axios.post(`${urlApi}/api/v1/districtquotas`, newData);
        showAlertWithAutoDismiss("Record saved successfully.");
      }
      // }

      clearModalFields();
      await fetchData();
      handleCloseModal();
    } catch (error) {
      console.error("Error adding/updating data:", error);
    }
  };

  const deleteDataFromTable = async (id) => {
    setDeleteId(id);
    setShowConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${urlApi}/api/v1/districtquotas/${deleteId}`);
      fetchData();
      showAlertWithAutoDismiss("Record successfully deleted.");
    } catch (error) {
      console.error("Error deleting district quota:", error);
    }
    setShowConfirmation(false);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setDeleteId(null);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    clearModalFields();
  };

  const openUpdateModal = async (index) => {
    const cumulativeIndex = indexOfFirstEntry + index;
    const selectedDistrictQuota = districtQuotas[cumulativeIndex];
    const districtQuotaResponse = await axios.get(
      `${urlApi}/api/v1/districtquotas/${selectedDistrictQuota.id}`
    );
    setCode(selectedDistrictQuota.code);
    setFinancialYear(selectedDistrictQuota.financialyear);
    setStartDate(selectedDistrictQuota.startdate);
    setEndDate(selectedDistrictQuota.enddate);
    setLines(
      selectedDistrictQuota.lines ||
        districtQuotaResponse.data.lines.map((district) => ({
          districtid: district.districtid,
          quota: district.quota,
        }))
    );

    setSelectedIndex(cumulativeIndex);
    handleShowModal();
  };

  const clearModalFields = () => {
    setCode("");
    setFinancialYear("");
    setStartDate("");
    setEndDate("");
    setLines([{ lineindex: "", districtid: "", quota: "" }]);
    setSelectedIndex(null);
    setCodeError("");
    setFinancialYearError("");
    setStartDateError("");
    setEndDateError("");
  };

  const handleQuotaChange = (districtid, index, value) => {
    const formattedValue = formatCurrency(value);
    const updatedLines = [...lines];
    updatedLines[index] = {
      ...updatedLines[index],
      districtid,
      quota: value,
    };
    setLines(updatedLines);
  };
  const validateForm = () => {
    let isValid = true;

    if (!code.trim()) {
      setCodeError("Code is required");
      isValid = false;
    } else {
      setCodeError("");
    }

    if (!financialYear.trim()) {
      setFinancialYearError("Financial Year is required");
      isValid = false;
    } else {
      setFinancialYearError("");
    }

    if (!startDate.trim()) {
      setStartDateError("Start Date is required");
      isValid = false;
    } else {
      setStartDateError("");
    }

    if (!endDate.trim()) {
      setEndDateError("End Date is required");
      isValid = false;
    } else {
      setEndDateError("");

      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      if (endDateObj < startDateObj) {
        setEndDateError("End Date can't be less than Start Date");
        isValid = false;
      } else {
        setEndDateError("");
      }
    }

    return isValid;
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    if (alertCallback) {
      alertCallback();
    }
    setAutoDismiss(false);
  };

  const showAlertMessage = (message, callback = null, dismissAfter = null) => {
    setAlertMessage(message);
    setAlertCallback(callback);
    setShowAlert(true);
    if (dismissAfter) {
      setTimeout(() => {
        handleAlertClose();
      }, dismissAfter);
    }
  };

  const showAlertWithAutoDismiss = (message) => {
    showAlertMessage(message, null, 2000);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  
  useEffect(() => {
    const handleInputChange = function () {
      const searchTerm = this.value.toLowerCase();
      const columnIndex = this.parentElement.cellIndex;
      const table = document.querySelector(".table-container");
      const rows = table.querySelectorAll("tbody tr");

      rows.forEach(function (row) {
        const cellValue = row.cells[columnIndex].textContent.toLowerCase();

        if (cellValue.includes(searchTerm)) {
          row.style.display = "";
        } else {
          row.style.display = "none";
        }
      });
    };

    const searchInputs = document.querySelectorAll(".table-filter");

    searchInputs.forEach(function (input) {
      input.addEventListener("input", handleInputChange);
    });

    return () => {
      searchInputs.forEach(function (input) {
        input.removeEventListener("input", handleInputChange);
      });
    };
  }, []);
  return (
    <div id="district_Quota" className="container-fluid mt-4">
      <hr />
      <div className="new_pagin">
        <div className="addingButton">
          <button onClick={handleShowModal}>
            <FaPlus
              style={{
                color: "#00483f",
                fontSize: "10px",
                marginBottom: "1px",
              }}
            />
            <span>New</span>
          </button>
        </div>

        <CustomAlert
          message={alertMessage}
          show={showAlert}
          onClose={handleAlertClose}
        />
        <ConfirmationMessage
          message="Are you sure you want to delete this record?"
          show={showConfirmation}
          onCancel={handleCloseConfirmation}
          onConfirm={confirmDelete}
        />
        <div className="btnPagDrp">
          <Pagination className="pagination">
            <Pagination.First
              className="bgc ldarrow"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              <MdKeyboardDoubleArrowLeft />
            </Pagination.First>
            <Pagination.Prev
              className="bgc larrow"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </Pagination.Prev>
            <Pagination.Item disabled>
              {`Page: ${currentPage} of ${totalPages}`}
            </Pagination.Item>
            <Pagination.Next
              className="bgc rarrow"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </Pagination.Next>
            <Pagination.Last
              className="bgc rddarrow"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardDoubleArrowRight />
            </Pagination.Last>
          </Pagination>
          <Form.Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="rowperpage"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>

      <div id="complete_modal" className="complete_modal">
        <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
          <div className="modal_header">
            <div className="modal_heading">
              <Modal.Title>
                <span>District Quota</span>
              </Modal.Title>
            </div>
            <div className="closeicon">
              <CloseButton
                variant="white"
                className="bn_close"
                onClick={handleCloseModal}
              />
            </div>
          </div>

          <div className="line"></div>

          <Modal.Body>
            <Form>
              <div className="districtQuota">
                <div className="comboflex">
                  <div className="districtQuota1">
                    <Form.Group className="mb-2" controlId="city_code">
                      <Form.Label className="formlabel">
                        <span>
                          Code<span className="mandatoryField">*</span>
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        isInvalid={!!codeError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {codeError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="comboflex">
                  <div className="districtQuota2">
                    <Form.Group className="mb-2" controlId="city_country">
                      <Form.Label className="formlabel">
                        <span>
                          Financial Year
                          <span className="mandatoryField">*</span>
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Financial Year"
                        value={financialYear}
                        onChange={(e) => setFinancialYear(e.target.value)}
                        isInvalid={!!financialYearError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {financialYearError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="comboflex">
                  <div className="districtQuota1">
                    <Form.Group className="mb-2" controlId="city_state">
                      <Form.Label className="formlabel">
                        <span>
                          Start Date<span className="mandatoryField">*</span>
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        isInvalid={!!startDateError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {startDateError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="districtQuota1">
                    <Form.Group className="mb-2" controlId="city_city">
                      <Form.Label className="formlabel">
                        <span>
                          End Date<span className="mandatoryField">*</span>
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        isInvalid={!!endDateError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {endDateError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                {/* // tab */}
                <Tabs
                  className="dealer_tab"
                  defaultActiveKey="Quota"
                  id="fill-tab-example"
                  style={{ flex: "0 0 auto;" }}
                  fill
                >
                  <Tab eventKey="Quota" title="Quota">
                    <div
                      className="district_grid"
                      style={{  overflowY: "auto" }}
                    >
                      <div className="table-container quotatableheight">
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          style={{ width: "50%" }}
                        >
                          <thead>
                            <tr className="tb-heading">
                              <th style={{ width: "2%" }}>S.No</th>
                              <th style={{ width: "4%" }}>Code</th>
                              <th style={{ width: "20%" }}>District</th>
                              <th style={{ width: "20%" }}>
                                Monthly Quota (TON)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {districts.map((district, index) => (
                              <tr className="tb-sub" key={district.id}>
                                <td>{index + 1}</td>
                                <td>{district.code}</td>
                                <td>{district.district}</td>
                                <td className="districtRows">
                                  <Form.Control
                                    type="text"
                                    value={
                                      lines[index] ? lines[index].quota : ""
                                    }
                                    onChange={(e) =>
                                      handleQuotaChange(
                                        district.id,
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="modalbutton"
              variant="outline-success"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="modalbutton"
              variant="outline-success"
              onClick={addDataToTable}
            >
              {selectedIndex !== null ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="parent_table">
        <div className="table-container">
          <Table striped bordered hover size="sm">
            <thead>
              <tr className="tb-heading">
                <th className="col-sm-1">Actions</th>
                <th className="col-sm-1">S.No</th>
                <th className="col-sm-1">Code</th>
                <th className="col-sm-1">Financial Year</th>
                <th className="col-sm-1">Start Date</th>
                <th className="col-sm-1">End Date</th>
              </tr>
              <tr className="tb-heading">
                <th></th>
                <th></th>
                <th>
                  <input type="text" className="table-filter" />
                </th>
                <th>
                  <input type="text" className="table-filter" />
                </th>
                <th>
                  <input type="text" className="table-filter" />
                </th>
                <th>
                  <input type="text" className="table-filter" />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((district, index) => {
                const cumulativeIndex = indexOfFirstEntry + index + 1;
                return (
                  <tr className="tb-sub" key={cumulativeIndex}>
                    <td>
                      <div className="tdcol">
                        <Button
                          className="bn"
                          onClick={() => openUpdateModal(index)}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          className="bn"
                          onClick={() => deleteDataFromTable(district.id)}
                        >
                          <MdDelete />
                        </Button>
                      </div>
                    </td>
                    <td>{cumulativeIndex}</td>
                    <td>{district.code}</td>
                    <td>{district.financialyear}</td>
                    <td>{district.startdate}</td>
                    <td>{district.enddate}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DistrictQuota;
