// Unit.jsx
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IcdqModal from "../../AppComponents/icdqmodal/icdqmodal";

const Unit = () => {
  const screenfields = [
    {
      caption: "Code",
      field: "code",
      type: "text",
      required: true,
      spacefield: 3,
    },
    {
      caption: "Unit",
      type: "text",
      field: "unit",
      spacefield: 2,
      required: true,
      fieldwidth: 6,
    },
  ];
  

  const tableColumns = [
    { field: 'code', label: 'Code', width: 20 },
    { field: 'unit', label: 'Unit', width: 100 },
  ];

  return (
    <IcdqModal GridWidth="30" FieldsArr={screenfields} title="Unit" controller="units" GridColumns={tableColumns} PrimaryKeyField="id" />
  );
};

export default Unit;
