import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function IcdqDropdown({
  caption,
  getValue = () => {},
  setValue,
  disabled,
  required,
  validate,
  data = [], // Default to an empty array
  valueField,
  displayField,
}) {
  const [fieldValue, setFieldValue] = useState("");
  const [fieldError, setFieldError] = useState("");

  const fieldChangeFunc = (value) => {
    setFieldValue(value);
    getValue(value);
    setFieldError("");
  };

  useEffect(() => {
    if (setValue !== undefined && setValue !== null) {
      setFieldValue(String(setValue)); // Ensure value is a string
    }
  }, [setValue]);

  useEffect(() => {
    if (validate) {
      let error = "";
      if (required && !fieldValue.trim()) {
        error = `${caption} cannot be empty`;
      }
      setFieldError(error);
    }
  }, [validate, fieldValue, caption, required]);

  // Validate that data is an array
  const options = Array.isArray(data) ? data : [];

  return (
    <div className="col-lg-3">
      <div className="field-dropdown">
        <Form.Group className="mb-2">
          <Form.Label className="formlabel">
            {caption}
            {required && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Select
            disabled={disabled}
            onChange={(e) => fieldChangeFunc(e.target.value)}
            value={fieldValue}
            isInvalid={!!fieldError} // Convert to boolean
          >
            <option hidden value=""></option>
            {options.map((option, index) => (
              <option key={index} value={option[valueField]}>
                {option[displayField]}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {fieldError}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </div>
  );
}

export default IcdqDropdown;