// Sugar Mill.jsx
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IcdqModal from "../../AppComponents/icdqmodal/icdqmodal";

const Sugarmill = () => {

  const screenfields = [
    {
      caption: "Code",
      field: "code",
      type: "text",
      disabled: true
    }, 
    {
      caption: "Country",
      fieldtype: 'dropdown',
      field: "country_id",
      fieldwidth: 3,
      valuefield: 'id',
      displayfield: "country_name",
      endpoint: "countries",
      spacefield: 2,
    },
    {
      caption: "State",
      fieldtype: 'dropdown',
      field: "state_id",
      fieldwidth: 3,
      valuefield: 'id',
      displayfield: "state_name",
      dependentOn: "country_id",  // Added dependentOn
      endpoint: "statesByCountry",
    },
    {
      caption: "City",
      fieldtype: 'dropdown',
      field: "city_id",
      fieldwidth: 3,
      spacefield: 2,
      valuefield: 'id',
      displayfield: "city_name",
      dependentOn: "state_id",  // Added dependentOn
      endpoint: "citiesByState",
    },
    {
      caption: "Mill Name",
      type: "text",
      field: "mill_name",
      required: true,
      fieldwidth: 6,
      spacefield: 2,
    },
    {
      caption: "Address",
      type: "textarea",
      field: "address",
      required: true,
      fieldwidth: 6,
      spacefield: 2,
    },
    {
      caption: "Contact Person",
      type: "text",
      field: "contactperson",
    },
    {
      caption: "Contact Number",
      type: "tel",
      field: "contactno",
      spacefield: 2,
    },
  ];

  const tableColumns = [
    { field: 'code', label: 'Code', width: 20 },
    { field: 'mill_name', label: 'Mill Name', width: 130 },
    { field: 'address', label: 'Mill Address', width: 160 },
    { field: 'contactperson', label: 'Contact Person', width: 90 },
    { field: 'contactno', label: 'Contact No.', width: 50 },
  ];

  return (
    <IcdqModal GridWidth="85" FieldsArr={screenfields} title="Sugar Mill" controller="sugarmill" GridColumns={tableColumns} PrimaryKeyField="id" />
  );
};

export default Sugarmill;