import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import html2pdf from "html2pdf.js";
import { useReactToPrint } from "react-to-print";

const RegisterationReport = ({ nocData }) => {
  const [formData, setFormData] = useState({
    NOC: "",
    Date: "",
    shippedFrom: "",
    Companyname: "",
    code: "",
    VehicleNo: "",
    VehicleDetail: "",
    Quantity: "",
    Wherehouse: "",
    Destination: "",
  });

  const pdfRef = useRef();

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const clearForm = () => {
    setFormData({
      NOC: "",
      Date: "",
      shippedFrom: "",
      Companyname: "",
      code: "",
      VehicleNo: "",
      VehicleDetail: "",
      Quantity: "",
      Wherehouse: "",
      Destination: "",
    });
  };

  const downloadAsPDF = () => {
    const input = pdfRef.current;
    const pdfOptions = {
      margin: 0,
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(input).set(pdfOptions).save();
  };

  const pdfOptions = {
    filename: "report.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  const openPrintPreview = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "NOC",
    ...pdfOptions,
  });



  console.log('nocData',nocData)

  return (
            <div className="certificate-bgimage" >
              <div className="modal-body">
                <div className="certificate-container">
                  <div className="content certContent">
                    <div className="no-date">
                      <p className="regNo">{nocData?.reg_no}</p>
                      <p className="regDate">{nocData?.reg_date}</p>
                    </div>
                    <div className="dealar-name">
                    </div>
                    <div className="subject">
                    </div>
                    <div className="subjectDetail">
                      <p>
                        It is hereby certified that <span>M/S.{nocData?.dealer_name} , DISTRICT {nocData?.district}</span> has been registered as a sugar dealer (Wholesaler/ Supplier/ Trader/ Retailer etc), vide Para I (ii) of approved Standard Operating Procedure (SOP) vide Industries & Commerce Department Notification No. SO-COMM/IND/1-1/A/2022/311-57 Dated 20<sup>th</sup> April, 2023, further amended vide Notification No. SO-(E-II)/IND/3-7/A/2022/PS-1340-53 Dated 17<sup>th</sup> October, 2023, in the record of this Directorate with the registration <span>NO.DIQ/REG/SIU/SUGAR/{nocData?.reg_no}.</span> This registration number must be quoted while corresponding with this Directorate General and all products must maintain general standards of quality.
                      </p>
                    </div>
                    <div className="Dealer-details">
                      <p>
                        Dealer Name:- <span>{nocData?.dealer_name}</span> <br />
                      </p>
                      <p>
                        CNIC NO:
                        <span> {nocData?.cnic}</span>
                      </p>
                    </div>
                    <div className="signature">
                      <div className="left"></div>
                      <div className="right DirectorSign">
                        <p>ASSISTANT DIRECTOR<br />INDUSTRIES & COMMERCE</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  );
};

export default RegisterationReport;
