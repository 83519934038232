// City.jsx
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IcdqModal from "../../AppComponents/icdqmodal/icdqmodal";

const City = () => {
  const screenfields = [
    {
      caption: "Code",
      field: "code",
      type: "text",
      required: true,
      spacefield: 3,
    },
    {
      caption: "Country",
      fieldtype: "dropdown",
      field: "country_id",
      required: true,
      fieldwidth: 3,
      valuefield: "id",
      displayfield: "country_name",
      endpoint: "countries",
    },
    {
      caption: "State",
      fieldtype: "dropdown",
      field: "state_id",
      spacefield: 2,
      required: true,
      fieldwidth: 3,
      valuefield: "id",
      displayfield: "state_name",
      dependentOn: "country_id", // Added dependentOn
      endpoint: "statesByCountry",
    },
    {
      caption: "City",
      field: "city_name",
      type: "text",
      required: true,
      spacefield: 3,
      fieldwidth: 6,
    },
  ];

  const tableColumns = [
    { field: "code", label: "Code", width: 20 },
    { field: "city_name", label: "City", width: 100 },
    { field: "state_name", label: "State", width: 100 },
    { field: "country_name", label: "Country", width: 100 },
  ];

  return (
    <IcdqModal
      GridWidth="60"
      FieldsArr={screenfields}
      title="City"
      controller="cities"
      GridColumns={tableColumns}
      PrimaryKeyField="id"
    />
  );
};

export default City;
