// // ConfirmationMessage.jsx
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationMessage = ({ message, onConfirm, onCancel, show }) => {
    return (
        <Modal show={show} onHide={onCancel} top className='SuccesModal'>

            <div className="succesheader">
                Delete
            </div>
            <div className="line"></div>
            <Modal.Body >
                <div className='successmessge'>
                <p>{message}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="button-save" variant="outline-success" onClick={onCancel}>
                    No
                </Button>
                <Button className="button-save" variant="outline-success" onClick={onConfirm}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationMessage;
