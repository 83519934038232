import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function IcdqInput({
  caption,
  getValue = () => {},
  setValue,
  disabled,
  type,
  required,
  validate,
  fieldwidth,
  currentdate
}) {
  const [fieldValue, setFieldValue] = useState("");
  const [fieldError, setFieldError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^03[0-9]{2}-[0-9]{7}$/;
    return phoneRegex.test(phone);
  };

  const validateCnic = (cnic) => {
    const cnicRegex = /^[0-9]{5}-[0-9]{7}-[0-9]$/;
    return cnicRegex.test(cnic);
  };

  const formatNumber = (value) => {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 4 || i === 11) {
        formattedValue += "-";
      }
      formattedValue += numericValue[i];
    }
    return formattedValue.slice(0, 12);
  };

  const formatCNIC = (value) => {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedValue += "-";
      }
      formattedValue += numericValue[i];
    }
    return formattedValue.slice(0, 15);
  };

  const fieldChangeFunc = (value) => {
    let formattedValue = value;
    if (type === "tel") {
      formattedValue = formatNumber(value);
    } else if (type === "cnic") {
      formattedValue = formatCNIC(value);
    }
    setFieldValue(formattedValue);
    getValue(formattedValue);
    setFieldError("");
  };

  const setFieldValueFun = () => {
    fieldChangeFunc(setValue);
  };

  useEffect(() => {
    if (setValue) {
      setFieldValueFun();
    }
  }, [setValue]);

  useEffect(() => {
    if(currentdate === true) {
      if (type === "date" && !setValue) {
        const currentDate = new Date().toISOString().split("T")[0];
        setFieldValue(currentDate);
        getValue(currentDate);
      }
    }
  }, [type, setValue, getValue]);

  useEffect(() => {
    if (validate) {
      let error = "";
      if (required && !fieldValue.trim()) {
        error = `${caption} cannot be empty`;
      } else if (type === "email" && fieldValue && !validateEmail(fieldValue)) {
        error = "Enter correct email format";
      } else if (
        type === "tel" &&
        fieldValue &&
        !validatePhoneNumber(fieldValue)
      ) {
        error = "Enter correct phone number format (03XZ-YYYYYYY)";
      } else if (type === "cnic" && fieldValue && !validateCnic(fieldValue)) {
        error = "Enter correct CNIC format (#####-#######-#)";
      }
      setFieldError(error);
    }
  }, [validate, fieldValue, caption, required, type]);

  return (
    <div className={`col-lg-${fieldwidth ? fieldwidth : "3"}`}>
      <div className="">
        <Form.Group className="mb-2">
          <Form.Label className="formlabel">
            {caption}
            {required && <span className="text-danger">*</span>}
          </Form.Label>
          {type === "textarea" ? (
            <Form.Control
              as="textarea"
              rows={3}
              disabled={disabled}
              placeholder={`Enter ${caption}`}
              onChange={(e) => fieldChangeFunc(e.target.value)}
              value={fieldValue}
              isInvalid={fieldError !== ""}
            />
          ) : (
            <Form.Control
              disabled={disabled}
              type={type}
              placeholder={`Enter ${caption}`}
              onChange={(e) => fieldChangeFunc(e.target.value)}
              value={fieldValue}
              isInvalid={fieldError !== ""}
            />
          )}
          <Form.Control.Feedback type="invalid">
            {fieldError}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </div>
  );
}

export default IcdqInput;