import React, { useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import html2pdf from "html2pdf.js";
import img1 from "../Noc/logo.png";
import img2 from "../Noc/Slogo.png";
import { Modal } from "react-bootstrap";
import Axios from "axios";
import { MdPrint } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { urlApi } from "../../../App";
import { useReactToPrint } from "react-to-print";
import noRecordFound from "./noRecordFound.png";

const NocSummery = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    NOC: "",
    Date: "",
    shippedFrom: "",
    Companyname: "",
    code: "",
    VehicleNo: "",
    VehicleDetail: "",
    Quantity: "",
    Wherehouse: "",
    Destination: "",
  });

  const [showModal, setShowModal] = useState(false);
  const pdfRef = useRef();
  const [dealers, setDealers] = useState([]);
  const [sugarMills, setSugarMills] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [fieldsDisabled, setfieldsDisabled] = useState(false);
  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const sugarMillData = async () => {
    try {
      const sugarMillsResponse = await Axios.get(`${urlApi}/api/v1/sugarmill`);
      setSugarMills(sugarMillsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    sugarMillData();
    dealerData();
    designationData();
  };

  // Qr code
  useEffect(() => {
    fetchData();
  }, []);

  // Qr code

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const dealerData = async () => {
    try {
      const dealersResponse = await Axios.get(`${urlApi}/api/v1/dealer`);
      setDealers(dealersResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const designationData = async () => {
    try {
      const destinationsResponse = await Axios.get(
        `${urlApi}/api/v1/destination`
      );
      setDestinations(destinationsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const downloadAsPDF = () => {
    const input = pdfRef.current;
    const pdfOptions = {
      margin: 10,
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
    };

    html2pdf().from(input).set(pdfOptions).save();
  };

  const pdfOptions = {
    // margin: 10,
    filename: "report.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault();
        openPrintPreview();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const openPrintPreview = useReactToPrint({
    content: () => {
      const contentElement = pdfRef.current;
      return contentElement;
    },
    documentTitle: "NOC Issuance Summary",
    ...pdfOptions,
  });

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const destinationId = formData.destination || "null";
      const fromToId = formData.from_to || "null";
      const dealerId = formData.dealer_id || "null";
      const sugarMillId = formData.sugar_mill || "null";
      const startDate = formData.from_date || currentDate;
      const tillDate = formData.till_date || currentDate;
      try {
        const response = await Axios.get(
          `${urlApi}/api/nocsummary/${destinationId}/${fromToId}/${dealerId}/${sugarMillId}/${startDate}/${tillDate}`
        );

        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
      console.log(startDate, tillDate, destinationId, fromToId);
    }

    fetchData();
  }, [
    formData.dealer_id,
    formData.sugar_mill,
    formData.destination,
    formData.from_to,
    formData.from_date,
    formData.till_date,
  ]);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data found.</div>;
  }
  const categorizedData = {};
  data.forEach((item) => {
    const { destination } = item;
    if (!categorizedData[destination]) {
      categorizedData[destination] = [item];
    } else {
      categorizedData[destination].push(item);
    }
  });

  return (
    <div>
      <form id="itemForm" className="NocSummaryReoprt">
        <div className="Gen-padf"></div>
        <div className="nocSummary_fields">
          <div className="nocSummaryText">
            <Form.Label className="formlabel">
              <span>Dealer</span>
            </Form.Label>
            <Form.Label className="formlabel">
              <span>Mill</span>
            </Form.Label>
            <Form.Label className="formlabel">
              <span>Shipped</span>
            </Form.Label>
            <Form.Label className="formlabel">
              <span>Destination</span>
            </Form.Label>
            <Form.Label className="formlabel">
              <span>From Date</span>
            </Form.Label>
            <Form.Label className="formlabel">
              <span>Till Date</span>
            </Form.Label>
          </div>
          <div>
            <Form.Group controlId="nocSummaryCombo" className="mb-2">
              <Form.Select
                value={formData.dealer_id}
                disabled={fieldsDisabled}
                onChange={(e) => handleInputChange("dealer_id", e.target.value)}
              >
                <option value="">Select Dealer Name...</option>
                {dealers &&
                  dealers.map((dealer) => (
                    <option key={dealer.id} value={dealer.id}>
                      {dealer.dealer_name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="nocSummaryCombo" className="mb-2">
              <Form.Select
                value={formData.sugar_mill}
                disabled={fieldsDisabled}
                onChange={(e) =>
                  handleInputChange("sugar_mill", e.target.value)
                }
              >
                <option value="">Select Mill Name...</option>
                {sugarMills &&
                  sugarMills.map((mill) => (
                    <option key={mill.id} value={mill.id}>
                      {mill.mill_name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="nocSummaryCombo" className="mb-2">
              <Form.Select
                value={formData.from_to || "null"}
                disabled={fieldsDisabled}
                onChange={(e) => handleInputChange("from_to", e.target.value)}
              >
                <option value="">Select From...</option>
                {destinations &&
                  destinations.map((from) => (
                    <option key={from.id} value={from.id}>
                      {from.destination}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="nocSummaryCombo" className="mb-2">
              <Form.Select
                value={formData.destination}
                disabled={fieldsDisabled}
                onChange={(e) =>
                  handleInputChange("destination", e.target.value)
                }
              >
                <option value="">Select Destination...</option>
                {destinations &&
                  destinations.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.destination}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="nocSummaryCombo" className="mb-2">
              <Form.Control
                type="date"
                name="from_date"
                value={formData.from_date || currentDate}
                disabled={fieldsDisabled}
                onChange={(e) => handleInputChange("from_date", e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="nocSummaryCombo" className="mb-2">
              <Form.Control
                type="date"
                name="till_date"
                value={formData.till_date || currentDate}
                disabled={fieldsDisabled}
                onChange={(e) => handleInputChange("till_date", e.target.value)}
              />
            </Form.Group>
          </div>
        </div>

        <div className="generatebutton">
          <button type="button" onClick={handleShowModal}>
            Generate
          </button>
        </div>
      </form>

      <div>
        <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
          <div className="ReportHeader">
            <Modal.Header closeButton>
              <div className="summeryReport">
                <Modal.Title>NOC Summary</Modal.Title>
              </div>
              <div className="downlodebutton">
                <div className="Pdf reportsIcons">
                  <button
                    type="button"
                    className="btn-outline-success printbtn"
                    id="downloadPdfBtn"
                    onClick={openPrintPreview}
                  >
                    <MdPrint />
                  </button>
                  <button
                    type="button"
                    className="btn-outline-success"
                    id="downloadPdfBtn"
                    onClick={downloadAsPDF}
                  >
                    <FaDownload />
                  </button>
                </div>
              </div>
            </Modal.Header>
          </div>
          <div ref={pdfRef}>
            <div className="printPrview">
              <div className="NocSummaryHeader">
                <div className="imgleft">
                  <img src={img1} alt="Balochistan" width="60px" />
                </div>

                <div className="NocSummaryHeading">
                  <div className="NocSummaryIssuance">
                    <h6>NOC Issuance Summary (Destination Wise)</h6>
                    <p>
                      <span>From Date: </span>{" "}
                      {formData.from_date || currentDate}{" "}
                      <span>Till Date: </span>
                      {formData.till_date || currentDate}
                    </p>
                  </div>
                </div>

                <div className="imgright">
                  <img src={img2} alt="NOC" width="60px" />
                </div>
              </div>

              <div className="ReportLine"></div>
              <div className="NocSummaryContainer">
                {Object.keys(categorizedData).length === 0 ? (
                  <div className="noRecordFound">
                    <img src={noRecordFound} alt="" />
                  </div>
                ) : (
                  Object.keys(categorizedData).map(
                    (destination, index, array) => (
                      <div key={destination}>
                        <h2>
                          Destination: <span>{destination}</span>
                        </h2>
                        <table className="NocSummaryTable">
                          <thead>
                            <tr>
                              <th style={{ width: "40px" }}>S.No</th>
                              <th style={{ width: "65px" }}>NOC No.</th>
                              <th style={{ width: "80px" }}>NOC Date</th>
                              <th style={{ width: "240px" }}>Dealer</th>
                              <th style={{ width: "240px" }}>Mill</th>
                              <th style={{ width: "120px" }}>Shipped</th>
                              <th style={{ width: "240px" }}>Driver Name</th>
                              <th style={{ width: "80px" }}>Vehicle No.</th>
                              <th style={{ width: "60px" }}>Weight</th>
                              <th style={{ width: "60px" }}>Unit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {categorizedData[destination].map((noc, index) => (
                              <tr key={noc.id}>
                                <td>{index + 1}</td>
                                <td>{noc.noc_no}</td>
                                <td>{noc.noc_date}</td>
                                <td>{noc.dealer_name}</td>
                                <td>{noc.mill_name}</td>
                                <td>{noc.from_to}</td>
                                <td>{noc.driver_name}</td>
                                <td>{noc.vehicle_no}</td>
                                <td>{noc.weight}</td>
                                <td>{noc.unit}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {index !== array.length - 1 && (
                          <hr className="NocSummaryHr" />
                        )}
                      </div>
                    )
                  )
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default NocSummery;
