import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IcdqModal from "../../AppComponents/icdqmodal/icdqmodal";

const District = () => {
  // Define the fields for the district form
  const screenfields = [
    {
      caption: "Code",
      field: "code",
      type: "text",
      required: true,
      spacefield: 3
    }, 
    {
      caption: "Country",
      fieldtype: 'dropdown',
      field: "countryid",
      required: true,
      fieldwidth: 3,
      valuefield: 'id',
      displayfield: "country_name",
      endpoint: "countries",
    },
    {
      caption: "State",
      fieldtype: 'dropdown',
      field: "stateid",
      spacefield: 2,
      required: true,
      fieldwidth: 3,
      valuefield: 'id',
      displayfield: "state_name",
      dependentOn: "countryid",  // Ensure this matches the field name of the country dropdown
      endpoint: "statesByCountry",
    },
    {
      caption: "District",
      type: "text",
      field: "district",
      spacefield: 2,
      required: true,
      fieldwidth: 6
    }
  ];

  // Define the columns for the district grid
  const tableColumns = [
    { field: 'code', label: 'Code', width: 20 },
    { field: 'district', label: 'District', width: 100 },
    { field: 'state_name', label: 'State', width: 100 }
  ];

  return (
    <div className="district-screen">
      <IcdqModal 
        GridWidth="45"
        FieldsArr={screenfields}
        title="District"
        controller="districts"
        GridColumns={tableColumns}
        PrimaryKeyField="id"
      />
    </div>
  );
};

export default District;
