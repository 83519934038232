// Country.jsx
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IcdqModal from "../../AppComponents/icdqmodal/icdqmodal";

const Country = () => {

  const screenfields = [
    {
        caption: "Code",
        field: "code",
        type: "text",
        required: true,
        spacefield: 3
    }, 
    {
        caption: "Country",
        type: "text",
        field: "country_name",
        spacefield: 2,
        required: true,
        fieldwidth: 6
    }
  ];


  const tableColumns = [
    { field: 'code', label: 'Code', width: 20 },
    { field: 'country_name', label: 'Country', width: 100 },
  ];

  return (
    <IcdqModal GridWidth="30" FieldsArr={screenfields} title="Country" controller="countries" GridColumns={tableColumns} PrimaryKeyField="id" />
  );
};

export default Country;
