import React, { useEffect, useState, useRef } from 'react';
import {
    Modal,
    CloseButton,
} from "react-bootstrap";
import RegisterationReport from '../../Components/Reports/RegisterationReport/RegisterationReport'
import Noc from '../../Components/Reports/Noc/Noc';
import { useReactToPrint } from "react-to-print";
import { MdPrint } from "react-icons/md";

function PrintModal({showModal, title, handleClosePrintModal, type, data}) {

    const pdfRef = useRef();

    useEffect(() => {
        console.log(type, data)
    }, [])

    const pdfOptions = {
        filename: `${title}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

    const openPrintPreview = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: "NOC",
        ...pdfOptions,
    });

    return (
        <Modal show={showModal} backdrop="static">
            <div className="modal_header">
                <div className="modal_heading">
                    <Modal.Title>
                        <span>{title}</span>
                    </Modal.Title>
                </div>
                <div className="closeicon" style={{display: 'flex', alignItems: 'center'}}>
                <button
                    style={{marginRight: '10px', border: 'none', background: 'none', color: '#fff', cursor: 'pointer'}}
                    type="button"
                    className="btn-outline-success printbtn"
                    id="downloadPdfBtn"
                    onClick={openPrintPreview}
                  >
                    <MdPrint />
                  </button>
                    <CloseButton
                        variant="white"
                        className="bn_close"
                        onClick={handleClosePrintModal}
                    />
                </div>
            </div>
            <div className="line"></div>
            <Modal.Body>
               <div ref={pdfRef}>
                    {
                        type === 'dealer' ? (
                            <RegisterationReport nocData={data}/>
                        ) : type === 'noc' ? (
                            <Noc nocData={data}/>
                        ) : (
                            <h1>Nothing</h1>
                        )
                    }
               </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}


export default PrintModal;