import React, { useEffect, useState } from 'react';
import {
    Modal,
    Button,
    Form,
    Table,
    CloseButton,
    TabContainer,
    TabContent,
    TabPane,
    Nav,
    NavItem
} from "react-bootstrap";
import axios from "axios";
import IcdqInput from '../icdq-input/icdq-input';
import IcdqDropdown from '../icdq-dropdown/IcdqDropdown';
import CustomAlert from '../../Components/CustomAlert/CustomAlert';
import { urlApi } from "../../App";
import IcdqGrid from '../icdq-Grid/IcdqGrid';
import ConfirmationMessage from '../../Components/ConfirmationMessage/ConfirmationMessage';
import PrintModal from '../print-modal/PrintModal'

const validateRequiredFields = (fields, formData) => {
    let errors = {};
    fields.forEach(field => {
        const value = formData[field.field] || '';
        if (field.required && typeof value === 'string' && !value.trim()) {
            errors[field.field] = `${field.field} cannot be empty`;
        }
        if (field.type === 'email' && value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            errors[field.field] = 'Enter correct email format';
        }
        if (field.type === 'tel' && value && !/^03[0-9]{2}-[0-9]{7}$/.test(value)) {
            errors[field.field] = 'Enter correct phone number format (03XZ-YYYYYYY)';
        }
        if (field.type === 'cnic' && value && !/^[0-9]{5}-[0-9]{7}-[0-9]$/.test(value)) {
            errors[field.field] = 'Enter correct CNIC format (#####-#######-#)';
        }
    });
    return errors;
};

function IcdqModal({printtype, gridEntriesPerPage, title, FieldsArr, childrens, controller, selectedItem, GridColumns, PrimaryKeyField, tabs = [], GridWidth, showTabs, detail, detailtable, print}) {
    const [showmodal, setShowmodal] = useState(false);

    const [fields, setFields] = useState([]);
    const [formData, setFormData] = useState({});
    const [rowid, setrowid] = useState(null);
    const [errors, setErrors] = useState({});
    const [validate, setValidate] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [RowData, setRowData] = useState([]);
    const [tabGridData, setTabGridData] = useState({});
    const [activeTab, setActiveTab] = useState(tabs.length > 0 ? tabs[0].tabid : '');

    useEffect(() => {
        setFields(FieldsArr);
        GetGridData();
        const initialFormData = {};
        FieldsArr.forEach(field => {
            initialFormData[field.field] = selectedItem ? selectedItem[field.field] || '' : '';
        });
        setFormData(initialFormData);

        FieldsArr.filter(field => field.fieldtype === 'dropdown' && !field.dependentOn).forEach(field => {
            fetchDropdownOptions(field.field, null, field.endpoint);
        });
        if(detail) {
            formData[detailtable] = [];
        }
    }, [FieldsArr, selectedItem]);

    useEffect(() => {
        // Fetch dependent options if formData updates with dependent fields
        FieldsArr.forEach(field => {
            if (field.dependentOn && formData[field.dependentOn]) {
                fetchDropdownOptions(field.field, formData[field.dependentOn], field.endpoint);
            }
        });
    }, [formData, FieldsArr]);

    const handleCloseModal = () => {
        setShowmodal(false);
        setValidate(false);
        setFormData({});
        setrowid(null);
    };

    const handleInputChange = (field, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));

        // Check for dependent fields
        const dependentFields = FieldsArr.filter(f => f.dependentOn === field);
        dependentFields.forEach(dependentField => {
            fetchDropdownOptions(dependentField.field, value, dependentField.endpoint);
            // Clear dependent dropdowns if parent changes
            setFormData(prevFormData => ({
                ...prevFormData,
                [dependentField.field]: '',
            }));
        });

        // Check if any tabs depend on this dropdown
        tabs.forEach(tab => {
            if(tab.dependentOn != null) {
                if (tab.dependentOn === field) {
                    fetchTabGridData(tab, value);
                }   
            } else {
                fetchTabGridData(tab, null)
            }
        });
    };

    const fetchTabGridData = async (tab, value) => {
        let FinalUrl = value != null ? `${urlApi}/api/v1/${tab.endpoint}/${value}`: `${urlApi}/api/v1/${tab.endpoint}`;
        try {
            const response = await axios.get(FinalUrl);
            setTabGridData(prevData => ({
                ...prevData,
                [tab.tabid]: response.data,
            }));
        } catch (error) {
            console.error(`Error fetching data for tab ${tab.tabid}:`, error);
        }
    };

    const Save = async (e) => {
        e.preventDefault();
        setValidate(true);
        let finalData = {};
        if(!detail){
            finalData = formData
        }else {
            finalData = formData;
            finalData[detailtable] = selectedCheckboxes
        }
        const validationErrors = validateRequiredFields(fields, formData);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                if (rowid !== null) {
                    await axios.put(`${urlApi}/api/v1/${controller}/${rowid}`, finalData);
                    setAlertMessage("Record updated successfully.");
                } else {
                    await axios.post(`${urlApi}/api/v1/${controller}`, finalData);
                    setAlertMessage("Record saved successfully.");
                }
                setShowmodal(false);
                setShowAlert(true);
                setValidate(false);
                setrowid(null);
                setFormData({});
                GetGridData();
            } catch (error) {
                console.error("Error saving data:", error);
            }
        }
    };

    const handleAlertClose = () => {
        setShowAlert(false);
        setAlertMessage("");
    };


    const GetGridData = async () => {
        try {
            const response = await axios.get(`${urlApi}/api/v1/${controller}`);
            setRowData(response.data);
        } catch (error) {
            console.error(`Error fetching ${controller}:`, error);
        }
    };

    const fetchDropdownOptions = async (field, dependentValue = null, endpoint) => {
        try {
            const url = dependentValue ? `${urlApi}/api/v1/${endpoint}/${dependentValue}` : `${urlApi}/api/v1/${endpoint}`;
            const response = await axios.get(url);
            setDropdownOptions(prevOptions => ({
                ...prevOptions,
                [field]: response.data,
            }));
        } catch (error) {
            console.error(`Error fetching ${field} options:`, error);
        }
    };

    const deleteDataFromTable = async (deleteid) => {
        setrowid(deleteid);
        setShowConfirmation(true);
    };

    const confirmDelete = async () => {
        try {
            await axios.delete(`${urlApi}/api/v1/${controller}/${rowid}`);
            GetGridData();
        } catch (error) {
            console.error(`Error deleting ${title}:`, error);
            if (error.response && error.response.status === 400) {
                // Handle error
            }
        }
        setShowConfirmation(false);
        setShowAlert(true);
        setAlertMessage("Record Deleted successfully...");
    };

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
        setrowid(null);
    };

    const openUpdateModal = async (data) => {
        setShowmodal(true);
        setFormData(data);
        setrowid(data[PrimaryKeyField]);
        tabs.forEach(tab => {
           if(tab.type === 'grid') {
                fetchTabGridData(tab, data[tab.dependentOn]);
           }
        });
        try {
            const response = await axios.get(`${urlApi}/api/v1/${controller}/${data[PrimaryKeyField]}`);
            const userRights = response.data[detailtable].map(
                (row) => row.district_id
            );
            setSelectedCheckboxes(userRights);
            // console.log(userRights)
        } catch(error) {
            console.log(error);
        }
    }
  
    const handleSelectAll = (isChecked) => {
        const updatedCheckboxes = isChecked
          ? Object.entries(tabGridData)[0][1].map((item) => item.districtid)
          : [];
        setSelectedCheckboxes(updatedCheckboxes);
    };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = [...selectedCheckboxes];
    if (updatedCheckboxes.includes(id)) {
      updatedCheckboxes.splice(updatedCheckboxes.indexOf(id), 1);
    } else {
      updatedCheckboxes.push(id);
    }
    setSelectedCheckboxes(updatedCheckboxes);
  };

  const [showprintmodal, setshowprintmodal] = useState(false); 
  const handleshowprintmodal = () => {
    setshowprintmodal(true);
  }

  const closeprint = () => {
    setshowprintmodal(false)
  }

    return (
        <>
            <CustomAlert
                message={alertMessage}
                show={showAlert}
                onClose={handleAlertClose}
            />
            <ConfirmationMessage
                message="Are you sure you want to delete this record?"
                show={showConfirmation}
                onCancel={handleCloseConfirmation}
                onConfirm={confirmDelete}
            />
            <Modal show={showmodal} onHide={handleCloseModal} backdrop="static">
                <div className="modal_header">
                    <div className="modal_heading">
                        <Modal.Title>
                            <span>{title}</span>
                        </Modal.Title>
                    </div>
                    <div className="closeicon">
                        <CloseButton
                            variant="white"
                            className="bn_close"
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
                <div className="line"></div>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            {fields.map((i) => (
                                <React.Fragment key={i.field}>
                                    {i.fieldtype === 'dropdown' ? (
                                        <IcdqDropdown
                                            required={i.required}
                                            caption={i.caption}
                                            getValue={(value) => handleInputChange(i.field, value)}
                                            validate={validate}
                                            data={dropdownOptions[i.field] || i.options}
                                            valueField={i.valuefield}
                                            displayField={i.displayfield}
                                            setValue={formData[i.field] || i.defaultValue}
                                            disabled={i.disabled}
                                        />
                                    ) : i.fieldtype === 'textarea' ? (
                                        <IcdqInput
                                            required={i.required}
                                            caption={i.caption}
                                            type="textarea"
                                            disabled={i.disabled}
                                            getValue={(value) => handleInputChange(i.field, value)}
                                            validate={validate}
                                            setValue={formData[i.field] || i.defaultValue}
                                            fieldwidth={i.fieldwidth}
                                        />
                                    ) : (
                                        <IcdqInput
                                            required={i.required}
                                            caption={i.caption}
                                            type={i.type}
                                            disabled={i.disabled}
                                            getValue={(value) => handleInputChange(i.field, value)}
                                            validate={validate}
                                            setValue={formData[i.field] || i.defaultValue}
                                            fieldwidth={i.fieldwidth}
                                            currentdate={i.currentdate}
                                        />
                                    )}
                                    {Array.from({ length: i.spacefield }).map((_, index) => (
                                        <div className='col-lg-3' key={index}></div>
                                    ))}
                                </React.Fragment>
                            ))}
                        </div>
                        {showTabs && (
                            <div className='modal-tabs'>
                            <TabContainer defaultActiveKey={activeTab}>
                                <Nav variant="tabs">
                                    {tabs.map((tab, index) => (
                                        <NavItem key={index}>
                                            <Nav.Link eventKey={tab.tabid}>{tab.title}</Nav.Link>
                                        </NavItem>
                                    ))}
                                </Nav>
                                <TabContent>
                                    {tabs.map((tab, index) => (
                                        <TabPane eventKey={tab.tabid} key={index}>
                                            {tab.type === 'fields' ? (
                                                <div className="row">
                                                    {tab.TabFields.map((i) => (
                                                        <React.Fragment key={i.field}>
                                                            {i.fieldtype === 'dropdown' ? (
                                                                <IcdqDropdown
                                                                    required={i.required}
                                                                    caption={i.caption}
                                                                    getValue={(value) => handleInputChange(i.field, value)}
                                                                    validate={validate}
                                                                    data={dropdownOptions[i.field] || i.options}
                                                                    valueField={i.valuefield}
                                                                    displayField={i.displayfield}
                                                                    setValue={formData[i.field] || i.defaultValue}
                                                                    disabled={i.disabled}
                                                                />
                                                            ) : i.fieldtype === 'textarea' ? (
                                                                <IcdqInput
                                                                    required={i.required}
                                                                    caption={i.caption}
                                                                    type="textarea"
                                                                    disabled={i.disabled}
                                                                    getValue={(value) => handleInputChange(i.field, value)}
                                                                    validate={validate}
                                                                    setValue={formData[i.field] || i.defaultValue}
                                                                    fieldwidth={i.fieldwidth}
                                                                />
                                                            ) : (
                                                                <IcdqInput
                                                                    required={i.required}
                                                                    caption={i.caption}
                                                                    type={i.type}
                                                                    disabled={i.disabled}
                                                                    getValue={(value) => handleInputChange(i.field, value)}
                                                                    validate={validate}
                                                                    setValue={formData[i.field] || i.defaultValue}
                                                                    fieldwidth={i.fieldwidth}
                                                                />
                                                            )}
                                                            {Array.from({ length: i.spacefield }).map((_, index) => (
                                                                <div className='col-lg-3' key={index}></div>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            ) : (
                                                <Table striped bordered hover size="sm" style={{ width: "50%" }}>
                                                    <thead>
                                                        <tr className="tb-heading">
                                                            <th style={{ width: 15 + "px" }}>
                                                                <input
                                                                    type="checkbox"
                                                                 onChange={(e) => handleSelectAll(e.target.checked)}
                                                                />
                                                            </th>
                                                            {tab.TabGridColumns && tab.TabGridColumns.map((column, index) => (
                                                                <th style={{width: column.width + "px"}} key={index}>{column.fieldname}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabGridData[tab.tabid] && tabGridData[tab.tabid].map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedCheckboxes.includes(item.id)}
                                                                        onChange={() => handleCheckboxChange(item.id)}
                                                                    />
                                                                </td>
                                                                {tab.TabGridColumns.map((column, colIndex) => (
                                                                    <td key={colIndex}>{item[column.field]}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            )}
                                        </TabPane>
                                    ))}
                                </TabContent>
                            </TabContainer>
                        </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='modalbutton' variant="outline-success" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='modalbutton' variant="outline-success" onClick={Save}>
                        {rowid ? "Update" : "Save"}
                    </Button>
                    <Button style={{display: print === true && rowid ? "block" : "none"}} className='modalbutton' onClick={handleshowprintmodal} variant="outline-success">
                        Print
                    </Button>
                </Modal.Footer>
            </Modal>

            <PrintModal showModal={showprintmodal} handleClosePrintModal={closeprint} data={formData} type={printtype} title={title}/>

            <IcdqGrid showRows={gridEntriesPerPage} GridWidth={GridWidth} openModal={() => setShowmodal(true)} tableColumns={GridColumns} tableData={RowData} PrimaryKeyField={PrimaryKeyField} openUpdateModal={(uptdata) => openUpdateModal(uptdata)} deleteDataFromTable={(rowid) => deleteDataFromTable(rowid)} />
        </>
    );
}

export default IcdqModal;