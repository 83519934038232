// Approval.jsx
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Table,
  CloseButton,
  Pagination,
} from "react-bootstrap";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { FaEdit, FaPlus } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import CustomAlert from "../../CustomAlert/CustomAlert";
import { urlApi } from "../../../App";

const Approval = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [noc, setNoc] = useState([]);
  const [formData, setFormData] = useState({
    noc_no: "",
    noc_date: "",
    dealer_name: "",
    mill_name: "",
    driver_name: "",
    vehicle_no: "",
    cnic: "",
    contact_no: "",
    from_to: "",
    destination: "",
    weight: "",
    ApprovalDate: currentDate,
    status_code: "",
    userid: "",
    remarks: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [approvaldateError, setApprovalDateError] = useState("");
  const [approvalstatusError, setApprovalStatusError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [autoDismiss, setAutoDismiss] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastEntry = currentPage * 10;
  const indexOfFirstEntry = indexOfLastEntry - 10;
  const totalPages = Math.ceil(noc.length / 10);
  const currentEntries = noc.slice(indexOfFirstEntry, indexOfLastEntry);
  const [statusOptions, setStatusOptions] = useState([]);
  const [alertCallback, setAlertCallback] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;

    if (!formData.ApprovalDate.trim()) {
      setApprovalDateError("Date is required");
      isValid = false;
    } else {
      setApprovalDateError("");
    }

    if (!formData.status_code.trim()) {
      setApprovalStatusError("Status is required");
      isValid = false;
    } else {
      setApprovalStatusError("");
    }

    return isValid;
  };
  const fetchStatusOptions = async () => {
    try {
      const response = await axios.get(`${urlApi}/api/v1/status`);
      setStatusOptions(response.data);
    } catch (error) {
      console.error("Error fetching status options:", error);
    }
  };

  const handleStatusChange = (statusId) => {
    setFormData({ ...formData, status_id: statusId });
  };
  useEffect(() => {
    fetchStatusOptions();
    const fetchData = async () => {
      try {
        const response = await axios.get(`${urlApi}/api/v1/noc`);

        const filteredData = response.data.filter(
          (item) => item.status_id === 1
        );
        setNoc(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    return () => {};
  }, []);
  const userid = localStorage.getItem("userid");
  const addDataToTable = async () => {
    console.log("Form Data:", formData);
    if (
      formData.noc_date === "" ||
      formData.driver_name === "" ||
      formData.vehicle_no === ""
    ) {
      alert("Please fill out all required fields!");
      return;
    }

    const approval = {
      noc_date: formData.currentDate,
      status_id: formData.status_id,
      remarks: formData.remarks,
      userid: selectedIndex !== null ? userid : 0,
    };
    try {
      if (selectedIndex !== null) {
        await axios.put(`${urlApi}/api/v1/noc/${formData.id}`, approval);
        showAlertWithAutoDismiss("Record updated.");
      } else {
        const response = await axios.post(`${urlApi}/api/v1/noc`, approval);
        const createdDealer = response.data;

        setNoc([...noc, createdDealer]);
      }

      clearModalFields();
      handleCloseModal();
    } catch (error) {
      console.error("Error adding/updating dealer:", error);
      alert(
        `Error adding/updating dealer. Please try again. Details: ${error.message}`
      );
    }
  };

  const clearModalFields = () => {
    setFormData({
      noc_no: "",
      noc_date: "",
      dealer_name: "",
      mill_name: "",
      vehicle_no: "",
      driver_name: "",
      cnic: "",
      contact_no: "",
      from_to: "",
      destination: "",
      weight: "",
      ApprovalDate: currentDate,
      status_code: "",
      userid: "",
      remarks: "",
    });
    setSelectedIndex(null);
  };

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    clearModalFields();
  };

  const openUpdateModal = (index) => {
    const cumulativeIndex = indexOfFirstEntry + index;
    const selectedDealer = noc[cumulativeIndex];
    setFormData(selectedDealer);
    setSelectedIndex(cumulativeIndex);
    handleShowModal();
  };

  useEffect(() => {
    const handleInputChange = function () {
      const searchTerm = this.value.toLowerCase();
      const columnIndex = this.parentElement.cellIndex;
      const table = document.querySelector(".table-container");
      const rows = table.querySelectorAll("tbody tr");

      rows.forEach(function (row) {
        const cellValue = row.cells[columnIndex].textContent.toLowerCase();

        if (cellValue.includes(searchTerm)) {
          row.style.display = "";
        } else {
          row.style.display = "none";
        }
      });
    };

    const searchInputs = document.querySelectorAll(".table-filter");

    searchInputs.forEach(function (input) {
      input.addEventListener("input", handleInputChange);
    });

    return () => {
      searchInputs.forEach(function (input) {
        input.removeEventListener("input", handleInputChange);
      });
    };
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    if (alertCallback) {
      alertCallback();
    }
    setAutoDismiss(false);
  };

  const showAlertMessage = (message, callback = null, dismissAfter = null) => {
    setAlertMessage(message);
    setAlertCallback(callback);
    setShowAlert(true);
    if (dismissAfter) {
      setTimeout(() => {
        handleAlertClose();
      }, dismissAfter);
    }
  };

  const showAlertWithAutoDismiss = (message) => {
    showAlertMessage(message, null, 2000);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  return (
    <div id="approval" className="container-fluid mt-4 approval">
      <hr />
      <div className="new_pagin">
        <div className="addingButton"></div>

        <CustomAlert
          message={alertMessage}
          show={showAlert}
          onClose={handleAlertClose}
        />

        <div className="btnPagDrp">
          <Pagination>
            <Pagination.First
              className="bgc ldarrow"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              <MdKeyboardDoubleArrowLeft />
            </Pagination.First>
            <Pagination.Prev
              className="bgc larrow"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </Pagination.Prev>
            <Pagination.Item disabled>
              {`Page: ${currentPage} of ${totalPages}`}
            </Pagination.Item>
            <Pagination.Next
              className="bgc rarrow"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </Pagination.Next>
            <Pagination.Last
              className="bgc rddarrow"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardDoubleArrowRight />
            </Pagination.Last>
          </Pagination>
          <Form.Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="rowperpage"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>

      <div id="complete_modal" className="complete_modal">
        <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
          <div className="modal_header">
            <div className="modal_heading">
              <Modal.Title>
                <span>Approval</span>
              </Modal.Title>
            </div>
            <div className="closeicon">
              <CloseButton
                variant="white"
                className="bn_close"
                onClick={handleCloseModal}
              />
            </div>
          </div>

          <div className="line"></div>

          <Modal.Body>
            <Form>
              <div className="approval_fields">
                <div className="comboflex">
                  <div className="approval_fields1">
                    <Form.Group className="mb-2" controlId="NOC_Approval">
                      <Form.Label className="formlabel">
                        <span>NOC No.</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="NOC No."
                        name="noc_no"
                        value={formData.noc_no}
                        onChange={(e) =>
                          handleInputChange("noc_no", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>

                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>NOC Date</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="noc_date"
                        value={formData.noc_date}
                        onChange={(e) =>
                          handleInputChange("noc_date", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="comboflex">
                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>Dealer Name</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Dealer Name"
                        name="dealer_name"
                        value={formData.dealer_name}
                        onChange={(e) =>
                          handleInputChange("dealer_name", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>

                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>Sugar Mill</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Sugar Mill"
                        name="mill_name"
                        value={formData.mill_name}
                        onChange={(e) =>
                          handleInputChange("mill_name", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>

                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>Driver Name</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Driver Name"
                        name="driver_name"
                        value={formData.driver_name}
                        onChange={(e) =>
                          handleInputChange("driver_name", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="comboflex">
                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>CNIC</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter CNIC"
                        name="cnic"
                        value={formData.cnic}
                        onChange={(e) =>
                          handleInputChange("cnic", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>

                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>Vehicle No.</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Vehicle No."
                        name="vehicle_no"
                        value={formData.vehicle_no}
                        onChange={(e) =>
                          handleInputChange("vehicle_no", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>

                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>Contact No.</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Contact No."
                        name="contact_no"
                        value={formData.contact_no}
                        onChange={(e) =>
                          handleInputChange("contact_no", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="comboflex">
                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>From</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter CNIC"
                        name="from_to"
                        value={formData.from_to}
                        onChange={(e) =>
                          handleInputChange("from_to", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>

                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>Destination</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Vehicle No."
                        name="destination"
                        value={formData.destination}
                        onChange={(e) =>
                          handleInputChange("destination", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>

                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>Weight</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Contact No."
                        name="weight"
                        value={formData.weight}
                        onChange={(e) =>
                          handleInputChange("weight", e.target.value)
                        }
                        disabled
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="comboflex">
                  <div className="approval_fields1">
                    <Form.Group controlId="NOC_Approval" className="mb-2">
                      <Form.Label className="formlabel">
                        <span>Approval Date*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="ApprovalDate"
                        value={formData.ApprovalDate || currentDate}
                        onChange={(e) =>
                          handleInputChange("ApprovalDate", e.target.value)
                        }
                        isInvalid={approvaldateError !== ""}
                      />
                      <Form.Control.Feedback type="invalid">
                        {approvaldateError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="approval_fields1">
                    <Form.Group
                      controlId="NOC_Approval_Status"
                      className="mb-2"
                    >
                      <Form.Label className="formlabel">
                        <span>Status</span>
                      </Form.Label>
                      <Form.Select
                        value={formData.status_id}
                        onChange={(e) => handleStatusChange(e.target.value)}
                      >
                        <option value="">Select Status...</option>
                        {statusOptions.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.status}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="approval_fields2">
                  <Form.Group controlId="NOC_Approval_02" className="mb-2">
                    <Form.Label className="formlabel">
                      <span>Remarks</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Remarks"
                      name="remarks"
                      value={formData.remarks}
                      onChange={(e) =>
                        handleInputChange("remarks", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="modalbutton"
              variant="outline-success"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="modalbutton"
              variant="outline-success"
              onClick={addDataToTable}
            >
              {selectedIndex !== null ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="parent_table">
        <div className="table-container">
          <Table striped bordered hover size="sm">
            <thead>
              <tr className="tb-heading">
                <th className="col-sm-1">Actions</th>
                <th className="col-sm-1">S.No</th>
                <th className="col-sm-1">NOC No.</th>
                <th className="col-sm-1">NOC Date</th>
                <th className="col-sm-1">Dealer Name</th>
                <th className="col-sm-1">Mill Name</th>
                <th className="col-sm-1">CNIC</th>
                <th className="col-sm-1">Contact No</th>
                <th className="col-sm-1">Status</th>
              </tr>
              <tr className="tb-heading">
                <th></th>
                <th></th>
                <th>
                  <input type="text" className="af4 table-filter" />
                </th>
                <th>
                  <input type="text" className="af2 table-filter" />
                </th>
                <th>
                  <input type="text" className="af3 table-filter" />
                </th>
                <th>
                  <input type="text" className="af3 table-filter" />
                </th>
                <th>
                  <input type="text" className="af1 table-filter" />
                </th>
                <th>
                  <input type="text" className="af5 table-filter" />
                </th>
                <th>
                  <input type="text" className="af2 table-filter" />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((dealer, index) => {
                const cumulativeIndex = indexOfFirstEntry + index + 1;
                return (
                  <tr className="tb-sub" key={cumulativeIndex}>
                    <td>
                      <Button
                        className="bn"
                        onClick={() => openUpdateModal(index)}
                      >
                        <FaEdit />
                      </Button>
                    </td>
                    <td>{cumulativeIndex}</td>
                    <td>{dealer.noc_no}</td>
                    <td>{dealer.noc_date}</td>
                    <td>{dealer.dealer_name}</td>
                    <td>{dealer.mill_name}</td>
                    <td>{dealer.cnic}</td>
                    <td>{dealer.contact_no}</td>
                    <td>{dealer.status_code}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Approval;
