
// NOC.jsx
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Table,
  CloseButton,
  Pagination,
} from "react-bootstrap";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FaEdit, FaPlus } from "react-icons/fa";
import CustomAlert from "../../CustomAlert/CustomAlert";
import ConfirmationMessage from "../../ConfirmationMessage/ConfirmationMessage";
import Axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Noc from "../../Reports/Noc/Noc";
import { urlApi } from "../../../App";

const NOC = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [noc, setNoc] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    noc_no: "",
    noc_date: currentDate,
    dealer_id: "",
    sugar_mill: "",
    from_to: "",
    destination: "",
    driver_name: "",
    vehicle_no: "",
    cnic: "",
    contact_no: "",
    weight: "",
    status_id: "",
    unit: "",
    // userid: "",
    remarks: "",
  });

  const nocDate = new Date('2023-07-29T00:00:00.000');
  const formattedDate = nocDate.toISOString().split('T')[0];



  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dealers, setDealers] = useState([]);
  const [sugarMills, setSugarMills] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [units, setUnits] = useState([]);
  const [currentEntries, setcurrentEntries] = useState([]);
  const [alertCallback, setAlertCallback] = useState(null);
  const [autoDismiss, setAutoDismiss] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentEntrieslength, setcurrentEntrieslength] = useState([]);
  const [fieldsDisabled, setfieldsDisabled] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [img, setImg] = useState("");
  const [nocReportData, setNocReportData] = useState(null);
  const [statusVal, setStatusVal] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastEntry = currentPage * itemsPerPage;
  const indexOfFirstEntry = indexOfLastEntry - itemsPerPage;
  const totalPages = Math.ceil(currentEntrieslength / itemsPerPage);

  const statusArray = [
    { id: 0, status: "All" },
    { id: 3, status: "Approved" },
    { id: 1, status: "Pending" },
    { id: 2, status: "Rejected" },
  ];

  const [data, setData] = useState({
    district: '', // Default district
    total_weight: 0,
    quota: 0,
    balance: 0,
  });
  useEffect(() => {
    fetchData();
  }, []);

  const dealerData = async () => {
    try {
      const dealersResponse = await Axios.get(`${urlApi}/api/v1/dealer`);
      setDealers(dealersResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const nocDatadelete = async () => {
  //   try {
  //     debugger
  //     const nocsResponse = await Axios.get(`${urlApi}/api/v1/noc`);
  //     if (
  //       nocsResponse &&
  //       nocsResponse.status == 200 &&
  //       nocsResponse.data &&
  //       Array.isArray(nocsResponse.data)
  //     ) {
  //       if (
  //         nocsResponse.data &&
  //         nocsResponse.data.length &&
  //         nocsResponse.data.length > 0
  //       ) {
  //         setNoc(nocsResponse.data);
  //         handlePageChange(1);
  //       } else {
  //         setCurrentPage(1);
  //         setcurrentEntries([]);
  //         setNoc([]);
  //         setStatusVal(0);
  //       }

  //     } else {
  //       console.error("Invalid data structure. Expected an array.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const nocData = async () => {
    try {
      const nocsResponse = await Axios.get(`${urlApi}/api/v1/noc`);
      if (
        nocsResponse &&
        nocsResponse.status == 200 &&
        nocsResponse.data &&
        Array.isArray(nocsResponse.data)
      ) {
        if (
          nocsResponse.data &&
          nocsResponse.data.length &&
          nocsResponse.data.length > 0
        ) {
          setNoc(nocsResponse.data);
          handleStatusListfirst(statusVal, nocsResponse.data);
        } else {
          setCurrentPage(1);
          setcurrentEntries([]);
          setNoc([]);
          setStatusVal(0);
        }
      } else {
        console.error("Invalid data structure. Expected an array.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sugarMillData = async () => {
    try {
      const sugarMillsResponse = await Axios.get(`${urlApi}/api/v1/sugarmill`);
      setSugarMills(sugarMillsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const designationData = async () => {
    try {
      const destinationsResponse = await Axios.get(
        `${urlApi}/api/v1/destination`
      );
      setDestinations(destinationsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const unitData = async () => {
    try {
      const destinationsResponse = await Axios.get(`${urlApi}/api/v1/units`);

      setUnits(destinationsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    dealerData();
    nocData();
    sugarMillData();
    designationData();
    unitData();
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await Axios.get(`${urlApi}/api/v1/status`);
      setStatusOptions(response.data);
    } catch (error) {
      console.error("Error fetching status options:", error);
    }
  };

  useEffect(() => {
    fetchStatusOptions();
    setImg();
  }, []);

  const handleStatusChange = (statusId) => {
    setFormData({ ...formData, status_id: statusId });
  };

  const handleStatusList = (statusId, pageNumber) => {
    setStatusVal(statusId);

    let count = pageNumber * itemsPerPage;
    let countnew = count - itemsPerPage;

    let data = noc.slice(countnew, count);

    if (statusId != 0) {
      let filterdata = data.filter((a) => a.status_id == statusId);
      setcurrentEntries(filterdata);
      setcurrentEntrieslength(filterdata.length);
    } else {
      setcurrentEntries(data);
      setcurrentEntrieslength(noc.length);
    }
  };

  const handleStatusListfirst = (statusId, data) => {
    setStatusVal(statusId);
    if (statusId != 0) {
      let data = data.slice(indexOfFirstEntry, indexOfLastEntry);
      let filterdata = data.filter((a) => a.status_id == statusId);
      setcurrentEntries(filterdata);
      setCurrentPage(1);
    } else {
      let datanew = data.slice(indexOfFirstEntry, indexOfLastEntry);
      setcurrentEntries(datanew);
      setCurrentPage(1);
      setcurrentEntrieslength(data);
    }
  };

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const addDataToTable = async () => {
    if (
      formData.noc_date === "" ||
      formData.dealer_id === "" ||
      formData.sugar_mill === "" ||
      formData.from_to === "" ||


      formData.vehicle_no === "" ||
      formData.cnic === "" ||
      formData.contact_no === "" ||
      formData.weight === "" ||
      formData.unit === ""
    ) {
      return;
    }
    const nocdata = {
      noc_date: formData.noc_date,
      dealer_id: formData.dealer_id,
      sugar_mill: formData.sugar_mill,
      from_to: formData.from_to,
      destination: formData.destination,
      driver_name: formData.driver_name,
      vehicle_no: formData.vehicle_no,
      cnic: formData.cnic,
      contact_no: formData.contact_no,
      weight: formData.weight,
      unit: formData.unit,
      // userid:  selectedIndex !== null ? userid : 0,
      status_id: formData.status_id || 1,
      remarks: formData.remarks,
    };

    try {
      if (selectedIndex !== null) {
        if (formData.status_id === 3) {
          showAlertMessage("Noc has Approved record cannot be update.");
        } else {
          await Axios.put(`${urlApi}/api/v1/noc/${formData.id}`, nocdata);
          showAlertWithAutoDismiss("Record updated.");
          nocData();
        }

        // nocDatadelete();
      } else {
        const response = await Axios.post(`${urlApi}/api/v1/noc`, nocdata);
        showAlertWithAutoDismiss("Record saved successfully.");
        setStatusVal(0);
        nocData();
      }
      clearModalFields();
      handleCloseModal();
      setfieldsDisabled(false);
    } catch (error) {
      if (error && error.response && error.response.status === 409) {
        showAlertWithAutoDismiss("Monthly Quota limit Exceeded.");
      } else if (error && error.response && error.response.status === 408) {
        showAlertWithAutoDismiss("Financial year Not found.");
      } else if (error && error.response && error.response.status === 403) {
        showAlertWithAutoDismiss("District Quota Not Defined.");
      } else if (error && error.response && error.response.status === 406) {
        showAlertWithAutoDismiss("District quota exhausted for the month.");
      }
    }
  };

  const deleteDataFromTable = async (dealer) => {
    const dealerId = dealer.id;
    if (dealer.status_id === 3) {
      showAlertMessage("Due to NOC approval, this record cannot be deleted.");
    } else {
      setDeleteId(dealerId);
      setShowConfirmation(true);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await Axios.delete(`${urlApi}/api/v1/noc/${deleteId}`);
      if (response && response.status == 200) {
        setCurrentPage(1);
        setNoc([]);
        nocData();
        nocData();
      }
      showAlertWithAutoDismiss("Record successfully deleted.");
    } catch (error) {
      console.error("Error deleting country:", error);

      showAlertMessage("Error deleting country. Please try again later.");
    }
    setShowConfirmation(false);
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setDeleteId(null);
  };

  const clearModalFields = () => {
    setFormData({
      noc_date: currentDate,
      dealer_id: "",
      sugar_mill: "",
      from_to: "",
      destination: "",
      driver_name: "",
      vehicle_no: "",
      cnic: "",
      contact_no: "",
      weight: "",
      status: "",
      // userid: "",
      remarks: "",
    });
    setSelectedIndex(null);
  };

  const handleShowModal = () => {
    setShowModal(true);
    formData.unit = units.filter((c) => c.unit)[0].id;
  };

  const handleCloseModal = () => {
    setShowModal(false);
    clearModalFields();
    setfieldsDisabled(false);
  };

  const openUpdateModal = (index) => {
    const cumulativeIndex = indexOfFirstEntry + index;
    const selectedDealer = noc[cumulativeIndex];
    setFormData(selectedDealer);
    if (selectedDealer.status_id !== 3 && selectedDealer.status_id !== 2) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
    setSelectedIndex(cumulativeIndex);
    setIsEditMode(true);
    handleShowModal();
  };

  const formatCNIC = (value) => {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedValue += "-";
      }
      formattedValue += numericValue[i];
    }
    return formattedValue.slice(0, 15);
  };
  const formatNumber = (value) => {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 4 || i === 11) {
        formattedValue += "-";
      }
      formattedValue += numericValue[i];
    }
    return formattedValue.slice(0, 12);
  };

  useEffect(() => {
    const handleInputChange = function () {
      const searchTerm = this.value.toLowerCase();
      const columnIndex = this.parentElement.cellIndex;
      const table = document.querySelector(".table-container");
      const rows = table.querySelectorAll("tbody tr");

      rows.forEach(function (row) {
        const cellValue = row.cells[columnIndex].textContent.toLowerCase();

        if (cellValue.includes(searchTerm)) {
          row.style.display = "";
        } else {
          row.style.display = "none";
        }
      });
    };

    const searchInputs = document.querySelectorAll(".table-filter");

    searchInputs.forEach(function (input) {
      input.addEventListener("input", handleInputChange);
    });

    return () => {
      searchInputs.forEach(function (input) {
        input.removeEventListener("input", handleInputChange);
      });
    };
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    handleStatusList(statusVal, pageNumber);
  };

  const storedSignImg = localStorage.getItem("signImg");
  const handlePrint = async () => {
    try {
      const response = await Axios.get(`${urlApi}/api/v1/noc/${formData.id}`);
      setNocReportData(response.data);
      setImg(storedSignImg);
      setShowAlert(true);
    } catch (error) {
      console.error("Error fetching NOC data:", error);
    }
  };

  const handleAlertClose = () => {
    setNocReportData(null);
    setShowAlert(false);
    setAlertMessage("");
    if (alertCallback) {
      alertCallback();
    }
    setAutoDismiss(false);
  };

  const showAlertMessage = (message, callback = null, dismissAfter = null) => {
    setAlertMessage(message);
    setAlertCallback(callback);
    setShowAlert(true);
    if (dismissAfter) {
      setTimeout(() => {
        handleAlertClose();
      }, dismissAfter);
    }
  };

  const showAlertWithAutoDismiss = (message) => {
    showAlertMessage(message, null, 2000);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    handleStatusList(statusVal, 1); // Reset to the first page when changing items per page
  };
  // Fetch data based on noc_date and destination
  useEffect(() => {
    if (formData.noc_date && formData.destination) {
      const dateParts = formData.noc_date.split('-');
      const year = dateParts[0];
      const month = dateParts[1];
      const destination = formData.destination;
      console.log(dateParts, year, month, destination);
      Axios.get(`${urlApi}/api/v1/shownocbalance/${year}/${month}/${destination}`)
        .then(response => {
          const { total_weight, quota, balance } = response.data;
          setData({
            district: destination,
            total_weight,
            quota,
            balance,
          });
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [formData.noc_date, formData.destination]);
  return (
    <div id="dealer" className="container-fluid mt-4">
      <hr />
      <div className="new_pagin">
        <div className="addingButton">
          <button onClick={handleShowModal}>
            <FaPlus
              style={{
                color: "#00483f",
                fontSize: "10px",
                marginBottom: "1px",
              }}
            />
            <span>New</span>
          </button>
        </div>
        <div className="dealerstatusHome">
          <Form.Group controlId="dealer_State " className="mb-2">
            <Form.Label className="formlabel">
              <span>Status</span>
            </Form.Label>
            <Form.Select
              value={statusVal}
              onChange={(e) => {
                handleStatusList(e.target.value, 1);
                setCurrentPage(1);
              }}
            >
              {statusArray.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.status}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>

        <CustomAlert
          message={alertMessage}
          show={showAlert}
          onClose={handleAlertClose}
        />
        <ConfirmationMessage
          message="Are you sure you want to delete this record?"
          show={showConfirmation}
          onCancel={handleCloseConfirmation}
          onConfirm={confirmDelete}
        />
        <div className="btnPagDrp">
          <Pagination>
            <Pagination.First
              className="bgc ldarrow"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              <MdKeyboardDoubleArrowLeft />
            </Pagination.First>
            <Pagination.Prev
              className="bgc larrow"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </Pagination.Prev>
            <Pagination.Item disabled>
              {`Page: ${currentPage} of ${totalPages}`}
            </Pagination.Item>
            <Pagination.Next
              className="bgc rarrow"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </Pagination.Next>
            <Pagination.Last
              className="bgc rddarrow"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardDoubleArrowRight />
            </Pagination.Last>
          </Pagination>
          <Form.Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="rowperpage"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
      {nocReportData && (
        <Noc
          show={showAlert}
          onClose={handleAlertClose}
          nocData={nocReportData}
          img={img}
        />
      )}
      <div id="complete_modal" className="complete_modal">
        <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
          <div className="modal_header">
            <div className="modal_heading">
              <Modal.Title>
                <span>NOC</span>
              </Modal.Title>
            </div>
            <div className="closeicon">
              <CloseButton
                variant="white"
                className="bn_close"
                onClick={handleCloseModal}
              />
            </div>
          </div>

          <div className="line"></div>

          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col">
                  <div className="approval_fields">
                    <div className="comboflex">
                      <div className="approval_fields1">
                        <Form.Group
                          className="mb-2"
                          controlId="dealer_Registration_No"
                        >
                          <Form.Label className="formlabel">
                            <span>NOC No.</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Registration No."
                            disabled
                            name="NOCNo"
                            value={formData.noc_no}
                            onChange={(e) =>
                              handleInputChange("noc_no", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="approval_fields1">
                        <Form.Group controlId="dealer_NOC_Date" className="mb-2">
                          <Form.Label className="formlabel">
                            <span>NOC Date</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            value={formData.noc_date.split("T")[0]}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("noc_date", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>

                      <div className="approval_fields1">
                        <Form.Group
                          controlId="NOC_Approval_Status"
                          className="mb-2"
                        >
                          <Form.Label className="formlabel">
                            <span>Status</span>
                          </Form.Label>
                          <Form.Select
                            value={formData.status_id}
                            disabled
                            onChange={(e) => handleStatusChange(e.target.value)}
                          >
                            <option value="">Pending</option>
                            {statusOptions.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.status}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="comboflex">
                      <div className="approval_fields1">
                        <Form.Group
                          controlId="NOC_Approval_Status"
                          className="mb-2"
                        >
                          <Form.Label className="formlabel">
                            <span>Dealer Name</span>
                          </Form.Label>
                          <Form.Select
                            value={formData.dealer_id}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("dealer_id", e.target.value)
                            }
                          >
                            <option value="">Select Dealer Name...</option>
                            {dealers &&
                              dealers.map((dealer) => (
                                <option key={dealer.id} value={dealer.id}>
                                  {dealer.dealer_name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>

                      <div className="approval_fields1">
                        <Form.Group
                          controlId="NOC_Approval_Status"
                          className="mb-2"
                        >
                          <Form.Label className="formlabel">
                            <span>Mill Name</span>
                          </Form.Label>
                          <Form.Select
                            value={formData.sugar_mill}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("sugar_mill", e.target.value)
                            }
                          >
                            <option value="">Select Mill Name...</option>
                            {sugarMills &&
                              sugarMills.map((mill) => (
                                <option key={mill.id} value={mill.id}>
                                  {mill.mill_name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="comboflex">
                      <div className="approval_fields1">
                        <Form.Group
                          controlId="NOC_Approval_Status"
                          className="mb-2"
                        >
                          <Form.Label className="formlabel">
                            <span>From</span>
                          </Form.Label>
                          <Form.Select
                            value={formData.from_to}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("from_to", e.target.value)
                            }
                          >
                            <option value="">Select From...</option>
                            {destinations &&
                              destinations.map((from) => (
                                <option key={from.id} value={from.destination}>
                                  {from.destination}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="approval_fields1">
                        <Form.Group
                          controlId="NOC_Approval_Status"
                          className="mb-2"
                        >
                          <Form.Label className="formlabel">
                            <span>Destination</span>
                          </Form.Label>
                          <Form.Select
                            value={formData.destination}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("destination", e.target.value)
                            }
                          >
                            <option value="">Select Destination...</option>
                            {destinations &&
                              destinations.map((state) => (
                                <option key={state.id} value={state.destination}>
                                  {state.destination}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="comboflex">
                      <div className="approval_fields1">
                        <Form.Group controlId="dealer_Contact_No" className="mb-2">
                          <Form.Label className="formlabel">
                            <span>Driver Name</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            name="driver_name"
                            value={formData.driver_name}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("driver_name", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>

                      <div className="approval_fields1">
                        <Form.Group controlId="dealer_WhatsApp_No" className="mb-2">
                          <Form.Label className="formlabel">
                            <span>Vehicle No.</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Vehicle No."
                            name="vehicle_no"
                            value={formData.vehicle_no}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("vehicle_no", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="comboflex">
                      <div className="approval_fields1">
                        <Form.Group controlId="dealer_Contact_No" className="mb-2">
                          <Form.Label className="formlabel">
                            <span>CNIC</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter CNIC."
                            disabled={fieldsDisabled}
                            name="cnic"
                            value={formatCNIC(formData.cnic)}
                            onChange={(e) =>
                              handleInputChange("cnic", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>

                      <div className="approval_fields1">
                        <Form.Group controlId="dealer_WhatsApp_No" className="mb-2">
                          <Form.Label className="formlabel">
                            <span>Contact No.</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Contact No."
                            name="contact_no"
                            value={formatNumber(formData.contact_no)}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("contact_no", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="comboflex">
                      <div className="approval_fields1">
                        <Form.Group controlId="dealer_WhatsApp_No" className="mb-2">
                          <Form.Label className="formlabel">
                            <span>Weight (MT)</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            step="any"
                            placeholder="Enter Weight"
                            name="weight"
                            value={formData.weight}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("weight", e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="approval_fields1">
                        <Form.Group
                          controlId="NOC_Approval_Status"
                          className="mb-2"
                        >
                          <Form.Label className="formlabel">
                            <span>Unit</span>
                          </Form.Label>
                          <Form.Select
                            value={formData.unit}
                            disabled={fieldsDisabled}
                            onChange={(e) =>
                              handleInputChange("unit", e.target.value)
                            }
                          >
                            <option value="">Select unit...</option>
                            {units &&
                              units.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.unit}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="leftNocQuota"></div>
                    </div>

                    <div className="approval_fields2">
                      <Form.Group controlId="NOC_Approval_02" className="mb-2">
                        <Form.Label className="formlabel">
                          <span>Remarks</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Remarks"
                          name="remarks"
                          value={formData.remarks}
                          disabled={fieldsDisabled}
                          onChange={(e) =>
                            handleInputChange("remarks", e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="col d-flex  flex-column justify-content-center px-2">
                  {/* <div className="showbalance ">
                <p>
                  District: Quetta 
                </p>
                <p>
                  Total Weight: 897
                </p>
                <p>
                Quota: 12000000 
                </p>
                <p>
                  Balance: 12000000
                </p>
              </div> */}
                  <div className="showbalance px-4">
                    <div className="d-flex justify-content-between border-bottom border-secondary" >
                      <h6 className="gridlabeltitle">District:</h6>
                      <h6 className="gridlabel">
                        {data.district ? data.district : 'Select Destination'}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between border-bottom border-secondary">
                      <h6 className="gridlabeltitle">Quota:</h6>
                      <h6 className="gridlabel">
                        {data.quota ? Number(data.quota).toLocaleString() : 0}
                      </h6>
                    </div>
                    {/* <div className="d-flex justify-content-between border-bottom border-secondary">
                      <h6 className="gridlabeltitle">Total Weight:</h6>
                      <h6 className="gridlabel">{data.total_weight || 'No Record'}</h6>
                    </div> */}

                    <div className="d-flex justify-content-between border-bottom border-secondary">
                      <h6 className="gridlabeltitle">Balance:</h6>
                      <h6 className="gridlabel">
                        {data.balance !== undefined ? data.balance.toLocaleString() : 0}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="modalbutton"
              variant="outline-success"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            {formData.status_id !== 3 && (
              <Button
                className="modalbutton"
                variant="outline-success"
                onClick={addDataToTable}
              >
                {selectedIndex !== null ? "Update" : "Save"}
              </Button>
            )}
            {isEditMode && formData.status_id === 3 && (
              <Button
                className="modalbutton"
                variant="outline-success"
                onClick={handlePrint}
              >
                Print
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>

      <div className="parent_table">
        <div className="table-container">
          <Table hover striped bordered size="sm">
            <thead>
              <tr className="tb-heading">
                <th className="col-sm-1">Actions</th>
                <th className="col-sm-1">S.No</th>
                <th className="col-sm-1">NOC No.</th>
                <th className="col-sm-1">NOC Date</th>
                <th className="col-sm-1">Dealer Name</th>
                <th className="col-sm-1">Mill Name</th>
                <th className="col-sm-1">From</th>
                <th className="col-sm-1">Destination</th>
                <th className="col-sm-1">Vehicle No.</th>
                <th className="col-sm-1">Status</th>
              </tr>
              <tr className="tb-heading">
                <th></th>
                <th></th>
                <th>
                  <input type="text" className="af4 table-filter" />
                </th>
                <th>
                  <input type="text" className="af2 table-filter" />
                </th>
                <th>
                  <input type="text" className="af3 table-filter" />
                </th>
                <th>
                  <input type="text" className="af3 table-filter" />
                </th>
                <th>
                  <input type="text" className="af1 table-filter" />
                </th>
                <th>
                  <input type="text" className="af1 table-filter" />
                </th>
                <th>
                  <input type="text" className="af2 table-filter" />
                </th>
                <th>
                  <input type="text" className="af2 table-filter" />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((dealer, index) => {
                const cumulativeIndex = indexOfFirstEntry + index + 1;
                return (
                  <tr className="tb-sub" key={cumulativeIndex}>
                    <td>
                      <div className="tdcol">
                        <Button
                          className="bn"
                          onClick={() => openUpdateModal(index)}

                        >
                          <FaEdit />
                        </Button>
                        <Button
                          className="bn"
                          onClick={() => deleteDataFromTable(dealer)}

                        >
                          <MdDelete />
                        </Button>
                      </div>
                    </td>
                    <td>{cumulativeIndex}</td>
                    <td>{dealer.noc_no}</td>
                    <td>{dealer.noc_date}</td>
                    <td>{dealer.dealer_name}</td>
                    <td>{dealer.mill_name}</td>
                    <td>{dealer.from_to}</td>
                    <td>{dealer.destination}</td>
                    <td>{dealer.vehicle_no}</td>
                    <td>
                      {dealer && dealer.status_id === 1
                        ? "Pending"
                        : dealer.status_id === 2
                          ? "Rejected"
                          : dealer.status_id === 3
                            ? "Approved"
                            : ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>

          </Table>
        </div>
      </div>
    </div>
  );
};

export default NOC;
