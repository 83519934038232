import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import html2pdf from "html2pdf.js";
import img1 from "./logo.png";
import img2 from "./Slogo.png";
import { Modal } from "react-bootstrap";
import QRCode from "qrcode.react";
import { FaDownload } from "react-icons/fa";
import { MdPrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";


const Noc = ({ show, onClose, nocData }) => {
  const [formData, setFormData] = useState({
    NOC: "",
    Date: "",
    shippedFrom: "",
    Companyname: "",
    code: "",
    VehicleNo: "",
    VehicleDetail: "",
    Quantity: "",
    Wherehouse: "",
    Destination: "",
  });

  const [showModal, setShowModal] = useState(false);
  const pdfRef = useRef();

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const downloadAsPDF = () => {
    const input = pdfRef.current;
    const pdfOptions = {
      margin: 0,
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(input).set(pdfOptions).save();
  };

  const pdfOptions = {
    margin: -10,
    filename: "report.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  const openPrintPreview = useReactToPrint({
    content: () => {

      const contentElement = pdfRef.current;

      contentElement.style.margin = "15px";
      contentElement.style.padding = "5px";

      return contentElement;
    },
    documentTitle: "NOC",
    ...pdfOptions,
  });
  

  return (
    <div>
      <Modal show={show} onHide={onClose} top backdrop="static">
        <div className="paginationprint">
          <div className="ReportHeader">
            <Modal.Header closeButton>
              <div className="simplereport">
                <Modal.Title>NOC</Modal.Title>
              </div>
              <div className="downlodebutton">
                <div className="Pdf reportsIcons">
                  <button
                    type="button"
                    className="btn-outline-success printbtn"
                    id="downloadPdfBtn"
                    onClick={openPrintPreview}
                  >
                    <MdPrint />
                  </button>
                  <button
                    type="button"
                    className="btn-outline-success"
                    id="downloadPdfBtn"
                    onClick={downloadAsPDF}
                  >
                    <FaDownload />
                  </button>
                </div>
              </div>
            </Modal.Header>
          </div>
        </div>

        <div className="nocMinReport-content" ref={pdfRef}>
          <p className="Report-sngleuse">
            <b>Single Use Only </b>
          </p>
          <div className="">
              <div className="modal-body">
                <div className="container nocreportmodal">
                  <div className="row header">
                    <div className="col-4 logo leftNocLogo">
                      <img src={img1} alt="" height="100px" />
                    </div>
                    <div className="col-4 SWIFT">
                      <div className="swiftpass">
                        GOVERNMENT OF BALOCHISTAN DIRECTORATE GENERAL INDUSTRIES
                        AND COMMERCE QUETTA
                      </div>
                    </div>
                    <div className="col-4 logo2">
                      <img src={img2} alt="" height="100px" />
                    </div>
                  </div>
                  <div className="sectionheading">
                    <p>
                      MOVEMENT CERTIFICATE/ NO OBJECTION CERTIFICATE
                      <br />
                      FOR SUGAR
                    </p>
                  </div>
                  <div className="col-10">
                    <div className="Report-Date">
                      <p>
                        <b>ISSUANCE DATE: </b>
                        <span>{nocData.noc_date}</span>
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="pararaphQR">
                    <div className="col-10">
                      <section className="movement-certificate  text-left paragraph">
                        <p>
                          {" "}
                          It is certified that this office has no objection and
                          allows transportation of mentioned quantities of
                          sugar. The NOC is issued for one time use only from
                          and to the aforemention destination. This NOC is
                          issued under the approved standard operating Procedure
                          of sugar monitoring (SOP) Vide Government of
                          Balochistan Industries and commerce Department
                          Notification No.SO (E-II) IND/3-7/A/2022/PS-1340-53
                          Dated 17th October 2023
                        </p>
                      </section>
                    </div>
                    <div className="col-2">
                      <div className="qr-container">
                        <QRCode value={nocData.noc_no} className="qr-code" />
                        <div className="noc"></div>
                      </div>
                    </div>
                  </div>
                  <div className="ReportForms">
                    <div className="nopermit Col-4">NOC/Permit No:</div>
                    <div className="Col-8 nolin">
                      <span>{nocData.noc_no}</span>
                    </div>
                  </div>

                  <div id="dealerinput" className="ReportForms">
                    <div className="customReportId CNIC Col-4">
                      Dealer CNIC:
                    </div>
                    <div className="Col-4 cnclin">
                      <span>{nocData.dealer_cnic}</span>
                    </div>
                    <div className="Call Col-4">Dealer Cell Number:</div>
                    <div className="Col-4 nolin">
                      <span>{nocData.dealer_contact_no}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="address-dealer nopermit Col-6">
                      Name of Dealer:
                    </div>
                    <div className="Col-6 nolin">
                      <span>{nocData.dealer_name}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="address-dealer nopermit Col-6">
                      Address of Dealer/Warehouse:
                    </div>
                    <div className="Col-6 nolin">
                      <span>{nocData.address}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="address-dealer nopermit Col-6">
                      Dealer Registration No.
                    </div>
                    <div className="Col-6 nolin">
                      <span>{nocData.reg_no}</span>
                    </div>
                  </div>

                  <div className=" ReportForms">
                    <div className=" customReportId nopermit ">Vehicle No:</div>
                    <div className="Col-6 nolin">
                      <span>{nocData.vehicle_no}</span>
                    </div>
                  </div>

                  <div className=" ReportForms">
                    <div className="vehicle-dealer nopermit ">
                      Vehicle Driver Name:
                    </div>
                    <div className="nolin">
                      <span>{nocData.driver_name}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="customReportId nopermit ">Contact No:</div>
                    <div className="nolin">
                      <span>{nocData.contact_no}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="customReportId nopermit ">
                      Vehicle Driver CNIC No.
                    </div>
                    <div className="nolin">
                      <span>{nocData.cnic}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="nopermit ">From:</div>
                    <div className="nolin">
                      <span>{nocData.from_to}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="nopermit">Destination:</div>
                    <div className="nolin">
                      <span>{nocData.destination}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="sugarmill-dealer nopermit ">
                      Name of the Sugar Mill:
                    </div>
                    <div className="nolin">
                      <span>{nocData.mill_name}</span>
                    </div>
                  </div>

                  <div className="ReportForms">
                    <div className="nopermit ">Number of bags/MT:</div>
                    <div className="nolin">
                      <span>{`${nocData.weight} ${nocData.unittons }`}</span>
                    </div>
                  </div>

                  <div className="row Not mt-3">
                    <div className="">
                      <p>
                        <b>
                          NOTE: This NOC is valid for ten days after issuing
                          date
                        </b>
                      </p>
                    </div>
                    <div className="additionalSignText">
                      <p>
                        <b>Sd</b>
                        <br />
                        <b>
                          Director General/ Controller General Prices/ Supplies
                          Balochistan, Quetta.
                        </b>
                      </p>
                    </div>
                  </div>

                  <div className="footerNoc">
                    <div className="footerright">
                      <li>
                        <b>Copy for information to:- </b>
                      </li>
                      <li>1. ACS, Home Department, Balochistan, Quetta.</li>
                      <li>2. Custom Collectoraite Balochistan.</li>
                      <li>3. Inspector General Police, Balochistan.</li>
                      <li>
                        <b>Copy for further information to:-</b>
                      </li>
                      <li>
                        1. Secretary, Industries and Commerce, Balochistan,
                        Quetta.
                      </li>
                      <li>
                        2. IG FC North and South.
                        <br />
                        3. Additional Secretary Staff to Chief Secretary
                        Balochistan.
                        <br />
                        4. Commissioner Concerned. <br />
                        5. Deputy Commissioner Concerned.
                      </li>
                    </div>
                    <div className="leftright">
                      <div className="sign">
                        <div className="sign">
                          {nocData.signImg && <img src={nocData.signImg } alt="Signature" />}
                        </div>
                      </div>
                      <b>
                        Issuing Authority
                        <br />
                        Director Industries & Commerce Balochistan, Quetta.
                      </b>
                      <b />
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        </Modal>
        </div>
  );
};

export default Noc;
