import React from "react";
import Fimg from "./Slogo.png";

export const Footer = () => {
  return (
    <div>
      <div className="mainfooter">
        <div className="rightside">
        </div>
        <div className="fotterimage">
          <p>
            All Rights Reserved by Industries And Commerce Department.{" "}
            <img src={Fimg} alt="" height={"30px"} />
          </p>
        </div>
      </div>
    </div>
  );
};
