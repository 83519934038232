  import React, { useState, useEffect } from "react";
  import axios from "axios";
  import {
    Modal,
    Button,
    Table,
    Form,
    CloseButton,
    Pagination,
    InputGroup,
  } from "react-bootstrap";
  import {
    MdKeyboardArrowRight,
    MdKeyboardArrowLeft,
    MdKeyboardDoubleArrowRight,
    MdKeyboardDoubleArrowLeft,
  } from "react-icons/md";
  import { MdDelete } from "react-icons/md";
  import { FaEdit, FaPlus } from "react-icons/fa";
  import { BsEye, BsEyeSlash } from "react-icons/bs";
  import "bootstrap/dist/css/bootstrap.min.css";
  import { urlApi } from "../../App";
  import CustomAlert from "../CustomAlert/CustomAlert";
  import ConfirmationMessage from "../ConfirmationMessage/ConfirmationMessage";

  const UserRegister = () => {
    const [users, setUsers] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [nameError, setNameError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [alertCallback, setAlertCallback] = useState(null);
    const [autoDismiss, setAutoDismiss] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [name, setName] = useState("");
    const [profileImg, setProfileImg] = useState(null);
    const [signImg, setSignImg] = useState(null);
    const [user_id, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [designationId, setDesignationId] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const totalPages = Math.ceil(users.length / 10);
    const indexOfLastEntry = currentPage * 10;
    const indexOfFirstEntry = indexOfLastEntry - 10;
    const currentEntries = users.slice(indexOfFirstEntry, indexOfLastEntry);
    const [showPassword, setShowPassword] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
      fetchUsers();
      fetchDesignation();
    }, []);

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${urlApi}/api/v1/getusers`);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const fetchDesignation = async () => {
      try {
        const designation = await axios.get(`${urlApi}/api/v1/designation`);
        setDesignation(designation.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const addDataToTable = async () => {
      if (!validateForm()) return;
      debugger
      try {
        if (selectedIndex !== null) {
          const response = await axios.put(
            `${urlApi}/register/${users[selectedIndex].id}`,
            {
              name,
              user_id,
              designationId,
              password,
              profileImg,
              signImg,
              user_rightdetail: selectedCheckboxes.map((screenid) => ({
                screenid,
              })),
            }
          );
          console.log(response.data, "success put")
          showAlertMessage("Update Record");
          if (response.data && response.data.success) {
            fetchUsers();
            clearModalFields();
            handleCloseModal();
          } else {
            showAlertMessage("Update failed. Please try again.");
          }
        } else {

          const response = await axios.post(`${urlApi}/register`, {
            name,
            user_id,
            password,
            designationId,
            profileImg,
            signImg,
            user_rightdetail: selectedCheckboxes.map((screenid) => ({
              screenid,
            })),
          });
          console.log(response.data, "success post")
          showAlertMessage("Save Record");
          if (response.data && response.data.success) {
            fetchUsers();
            clearModalFields();
            handleCloseModal();
          } else {
            showAlertMessage("Registration failed. Please try again.");
          }
        }
      } catch (error) {
        showAlertMessage("Operation failed. Please try again.");
      }
    };

    const validateForm = () => {
      let valid = true;

      if (name.trim() === "") {
        setNameError("Name is required");
        valid = false;
      }

      if (user_id.trim() === "") {
        setEmailError("Email is required");
        valid = false;
      } else if (!/\S+@\S+\.\S+/.test(user_id)) {
        setEmailError("Invalid email format");
        valid = false;
      }

      if (selectedIndex === null && password.trim() === "") {
        setPasswordError("Password is required");
        valid = false;
      }

      return valid;
    };

    const openUpdateModal = async (index) => {
      const selectedUser = users[index];
      setEmail(selectedUser.user_id);
      setName(selectedUser.name);
      setDesignationId(selectedUser.designationId);
      setProfileImg(selectedUser.profileImg);
      setSignImg(selectedUser.signImg);
      setSelectedIndex(index);
      handleShowModal();
      // clearModalFields()
      try {
        const response = await axios.get(`${urlApi}/register/${selectedUser.id}`);
        const userRights = response.data.user.user_rightdetail.map(
          (detail) => detail.screenid
        );
        setSelectedCheckboxes(userRights);
      } catch (error) {
        console.error("Error fetching user rights:", error);
      }
    };

    const clearModalFields = () => {
      setName("");
      setEmail("");
      setPassword("");
      setProfileImg(null);
      setSignImg(null);
      setSelectedIndex(null);
      setSelectedCheckboxes([]);
      clearErrors();
    };

    const handleCheckboxChange = (sNo) => {
      const updatedCheckboxes = [...selectedCheckboxes];

      if (updatedCheckboxes.includes(sNo)) {
        updatedCheckboxes.splice(updatedCheckboxes.indexOf(sNo), 1);
      } else {
        updatedCheckboxes.push(sNo);
      }

      setSelectedCheckboxes(updatedCheckboxes);
    };

    const yourTableData = [
      { sNo: 1, menu: "Master", screen: "Country" },
      { sNo: 2, menu: "Master", screen: "State" },
      { sNo: 14, menu: "Master", screen: "District" },
      { sNo: 3, menu: "Master", screen: "City" },
      { sNo: 4, menu: "Master", screen: "Dealer" },
      { sNo: 5, menu: "Master", screen: "Sugar Mills" },
      { sNo: 11, menu: "Master", screen: "Destination" },
      { sNo: 15, menu: "Master", screen: "Destrict Quota" },
      { sNo: 16, menu: "Master", screen: "Designation" },
      { sNo: 12, menu: "Master", screen: "Unit" },
      { sNo: 6, menu: "Transaction", screen: "NOC" },
      { sNo: 7, menu: "Transaction", screen: "Approval" },
      { sNo: 9, menu: "Reports", screen: "NOC Summary" },
      { sNo: 10, menu: "Settings", screen: "User Rights" },
      { sNo: 13, menu: "Staff App", screen: "NOC Approval" },
      // { sNo: 14, menu: "Mobile App", screen: "Public App" },
    ];

    const handleCloseModal = () => {
      setShowModal(false);
      clearModalFields();
    };

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const clearErrors = () => {
      setEmailError("");
      setPasswordError("");
      setNameError("");
    };

    const handleSelectAll = (isChecked) => {
      const updatedCheckboxes = isChecked
        ? yourTableData.map((item) => item.sNo)
        : [];
      setSelectedCheckboxes(updatedCheckboxes);
    };

    const deleteDataFromTable = async (index) => {
      const userId = users[index].id;
      setDeleteId(userId);
      setShowConfirmation(true);
    };

    const confirmDelete = async () => {
      try {
        await axios.delete(`${urlApi}/register/${deleteId}`);
        fetchUsers();
        showAlertWithAutoDismiss("Record successfully deleted.");
      } catch (error) {
        showAlertMessage("Error deleting User. Please try again later.");
      }
      setShowConfirmation(false);
    };

    const handleCloseConfirmation = () => {
      setShowConfirmation(false);
      setDeleteId(null);
    };

    const handleAlertClose = () => {
      setShowAlert(false);
      setAlertMessage("");
      if (alertCallback) {
        alertCallback();
      }
      setAutoDismiss(false);
    };

    const showAlertMessage = (message, callback = null, dismissAfter = null) => {
      setAlertMessage(message);
      setAlertCallback(callback);
      setShowAlert(true);
      if (dismissAfter) {
        setTimeout(() => {
          handleAlertClose();
        }, dismissAfter);
      }
    };

    const showAlertWithAutoDismiss = (message) => {
      showAlertMessage(message, null, 2000);
    };

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const handleImageChange = (event, setImage) => {
      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const maxSize = 20 * 1024 * 1024; // 20MB in bytes

      if (file) {
        if (allowedTypes.includes(file.type)) {
          if (file.size <= maxSize) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImage(reader.result);
            };
            reader.readAsDataURL(file);
          } else {
            showAlertMessage("File size must be less than 20MB.");
          }
        } else {
          showAlertMessage("Only JPEG, JPG, and PNG formats are allowed.");
        }
      }
    };

    useEffect(() => {
      const handleInputChange = function () {
        const searchTerm = this.value.toLowerCase();
        const columnIndex = this.parentElement.cellIndex;
        const table = document.querySelector(".table-container");
        const rows = table.querySelectorAll("tbody tr");
  
        rows.forEach(function (row) {
          const cellValue = row.cells[columnIndex].textContent.toLowerCase();
  
          if (cellValue.includes(searchTerm)) {
            row.style.display = "";
          } else {
            row.style.display = "none";
          }
        });
      };
  
      const searchInputs = document.querySelectorAll(".table-filter");
  
      searchInputs.forEach(function (input) {
        input.addEventListener("input", handleInputChange);
      });
  
      return () => {
        searchInputs.forEach(function (input) {
          input.removeEventListener("input", handleInputChange);
        });
      };
    }, []);
    const handleItemsPerPageChange = (event) => {
      setItemsPerPage(parseInt(event.target.value));
      setCurrentPage(1);
    };

    return (
      // <div id="modal">
      <div id="userrights" className="userrights container-fluid mt-4">
        <hr />
        <div className="new_pagin">
          <div className="addingButton">
            <button onClick={handleShowModal}>
              <FaPlus
                style={{
                  color: "#00483f",
                  fontSize: "10px",
                  marginBottom: "1px",
                }}
              />
              <span>New</span>
            </button>
          </div>

          <CustomAlert
            message={alertMessage}
            show={showAlert}
            onClose={handleAlertClose}
          />
          <ConfirmationMessage
            message="Are you sure you want to delete this record?"
            show={showConfirmation}
            onCancel={handleCloseConfirmation}
            onConfirm={confirmDelete}
          />
          <div className="btnPagDrp">
            <Pagination>
              <Pagination.First
                className="bgc ldarrow"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                <MdKeyboardDoubleArrowLeft />
              </Pagination.First>
              <Pagination.Prev
                className="bgc larrow"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <MdKeyboardArrowLeft />
              </Pagination.Prev>
              <Pagination.Item disabled>
                {`Page: ${currentPage} of ${totalPages}`}
              </Pagination.Item>
              <Pagination.Next
                className="bgc rarrow"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <MdKeyboardArrowRight />
              </Pagination.Next>
              <Pagination.Last
                className="bgc rddarrow"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                <MdKeyboardDoubleArrowRight />
              </Pagination.Last>
            </Pagination>
            <Form.Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="rowperpage"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Form.Select>
          </div>
        </div>

        <div id="complete_modal" className="complete_modal">
          <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
            <div className="modal_header">
              <div className="modal_heading">
                <Modal.Title>
                  <span>User Rights</span>
                </Modal.Title>
              </div>
              <div className="closeicon">
                <CloseButton
                  variant="white"
                  className="bn_close"
                  onClick={handleCloseModal}
                />
              </div>
            </div>

            <div className="line"></div>

            <Modal.Body>
              <Form>
                <div className="userrights_fields">
                  <div className="headeruserform">
                    <div className="leftuserrightForm">
                      <div className="comboflex">
                        <div className="approval_fields1">
                          <Form.Group className="mb-2" controlId="NOC_Approval">
                            <Form.Label className="formlabel">
                              <span>
                                Name<span className="mandatoryField">*</span>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              isInvalid={nameError !== ""}
                            />
                            <Form.Control.Feedback type="invalid">
                              {nameError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="comboflex">
                        <div className="approval_fields1">
                          <Form.Group className="mb-2" controlId="NOC_Approval">
                            <Form.Label className="formlabel">
                              <span>
                                Email<span className="mandatoryField">*</span>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter Email"
                              value={user_id}
                              onChange={(e) => setEmail(e.target.value)}
                              isInvalid={emailError !== ""}
                            />
                            <Form.Control.Feedback type="invalid">
                              {emailError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="approval_fields1">
                          <Form.Group className="mb-2" controlId="NOC_Approval">
                            <Form.Label className="formlabel">
                              Password<span className="mandatoryField">*</span>
                            </Form.Label>
                            <div className="password-toggle">
                              <InputGroup>
                                <Form.Control
                                  className={showPassword ? "" : "pw"}
                                  type="text"
                                  placeholder="Enter Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  isInvalid={passwordError !== ""}
                                />
                                <InputGroup.Text
                                  id="basic-addon2"
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? <BsEye /> : <BsEyeSlash />}
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {passwordError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="comboflex">
                        <div className="approval_fields1">
                          <Form.Group
                            className="mb-2"
                            controlId="NOC_Approval_Status"
                          >
                            <Form.Label className="formlabel">
                              <span>Designation</span>
                            </Form.Label>
                            <Form.Select
                              value={designationId}
                              onChange={(e) => setDesignationId(e.target.value)}
                            >
                              <option value="" disabled>
                                Select Designation...
                              </option>
                              {designation.map((d, index) => (
                                <option key={d.id} value={d.id}>
                                  {d.designation}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="comboflex">
                        <div className="approval_fields1">
                          <Form.Group className="mb-2" controlId="NOC_Approval">
                            <Form.Label className="formlabel">
                              <span>Picture</span>
                            </Form.Label>
                            <Form.Control
                              type="file"
                              accept="image/*"
                              onChange={(event) =>
                                handleImageChange(event, setProfileImg)
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="approval_fields1">
                          <Form.Group
                            className="mb-2"
                            controlId="NOC_Approval_Status"
                          >
                            <Form.Label className="formlabel">
                              <span>Signature</span>
                            </Form.Label>
                            <Form.Control
                              type="file"
                              accept="image/*"
                              onChange={(event) =>
                                handleImageChange(event, setSignImg)
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="rightuserimages">
                      <div className="image-container">
                        <div className="textprefix">
                          {profileImg && (
                            <div>
                              <img src={profileImg} alt="Profile" />
                              <h6>Picture</h6>
                            </div>
                          )}
                        </div>
                        <div className="textprefix">
                          {signImg && (
                            <div>
                              <img src={signImg} alt="Signature" />
                              <h6>Signature</h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <Table id="grid_table" hover size="sm">
                    <thead>
                      <tr className="tb-heading">
                        <th>
                          <label htmlFor="SelectAll">
                            <input
                              type="checkbox"
                              className="select"
                              id="SelectAll"
                              name=""
                              onChange={(e) => handleSelectAll(e.target.checked)}
                            />
                          </label>
                        </th>
                        <th>S.No</th>
                        <th>Menu</th>
                        <th>Screen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {yourTableData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              className="select"
                              name=""
                              checked={selectedCheckboxes.includes(item.sNo)}
                              onChange={() => handleCheckboxChange(item.sNo)}
                            />
                          </td>
                          <td>{`${index + 1}`}</td>
                          <td>{item.menu}</td>
                          <td>{item.screen}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button
                className="modalbutton"
                variant="outline-success"
                onClick={handleCloseModal}
              >
                Close
              </Button>
              <Button
                className="modalbutton"
                variant="outline-success"
                onClick={addDataToTable}
              >
                {selectedIndex !== null ? "Update" : "Save"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="parent_table">
          <div className="table-container">
            <Table striped bordered hover size="sm">
              <thead>
                <tr className="tb-heading">
                  <th className="col-sm-2">Actions</th>
                  <th className="col-sm-1">S.No</th>
                  <th className="col-sm-1">Name</th>
                  <th className="col-sm-1">User Email</th>
                </tr>
                <tr className="tb-heading">
                  <th></th>
                  <th></th>
                  <th>
                    <input type="text" className="f2 table-filter" />
                  </th>
                  <th>
                    <input type="text" className="f2 table-filter" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((user, index) => {
                  const cumulativeIndex = indexOfFirstEntry + index + 1;
                  return (
                    <tr className="tb-sub" key={cumulativeIndex}>
                      <td>
                        <div className="tdcol">
                          <Button
                            className="bn"
                            onClick={() => openUpdateModal(index)}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            className="bn"
                            onClick={() => deleteDataFromTable(index)}
                          >
                            <MdDelete />
                          </Button>
                        </div>
                      </td>
                      <td>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.user_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      // </div>
    );
  };

  export default UserRegister;
