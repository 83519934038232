import React, { useEffect, useState } from "react";
import { Table, Button, Pagination, Form } from "react-bootstrap";
import { FaEdit, FaPlus } from "react-icons/fa";
import {
  MdDelete,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

function IcdqGrid({
  tableData,
  tableColumns,
  openUpdateModal,
  deleteDataFromTable,
  PrimaryKeyField,
  openModal,
  GridWidth,
  showRows,
}) {
  const [filteredData, setFilteredData] = useState(tableData);
  const [filterValues, setFilterValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    showRows != null ? showRows : 10
  );

  useEffect(() => {
    setFilteredData(tableData);
  }, [tableData]);
//  // Sunain works 
//   useEffect(() => {
//     let filtered = tableData;
//     Object.keys(filterValues).forEach((index) => {
//       const value = filterValues[index];
//       if (value) {
//         filtered = filtered.filter((row) =>
//           row[tableColumns[index].field]
//             .toString()
//             .toLowerCase()
//             .includes(value)
//         );
//       }
//     });
//     setFilteredData(filtered);
//     setCurrentPage(1); // Reset to first page when filtering changes
//   }, [filterValues, tableData, tableColumns]);

  // const handleInputChange = (event, index) => {
  //   const value = event.target.value.toLowerCase();
  //   setFilterValues((prevValues) => ({
  //     ...prevValues,
  //     [index]: value,
  //   }));
  // };
// End sunain work.
//  work Umair
const handleInputChange = (event, index) => {
  const value = event.target.value.toLowerCase();
  setFilterValues((prevValues) => ({
    ...prevValues,
    [index]: value,
  }));
};

useEffect(() => {
  let filtered = tableData;
  Object.keys(filterValues).forEach((index) => {
    const value = filterValues[index];
    if (value) {
      filtered = filtered.filter((row) => {
        const cellValue = row[tableColumns[index].field];
        // Safely check and filter the value
        return cellValue && cellValue.toString().toLowerCase().includes(value);
      });
    }
  });
  setFilteredData(filtered);
  setCurrentPage(1); // Reset to first page when filtering changes
}, [filterValues, tableData, tableColumns]);

// end work 
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const indexOfLastEntry = currentPage * itemsPerPage;
  const indexOfFirstEntry = indexOfLastEntry - itemsPerPage;
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  return (
    <div className="container-fluid mt-4">
      <hr />
      <div className="new_pagin">
        <div className="addingButton">
          <button onClick={openModal}>
            <FaPlus
              style={{
                color: "#00483f",
                fontSize: "10px",
                marginBottom: "1px",
              }}
            />
            <span>New</span>
          </button>
        </div>
        <div className="btnPagDrp">
          <Pagination>
            <Pagination.First
              className="bgc ldarrow"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              <MdKeyboardDoubleArrowLeft />
            </Pagination.First>
            <Pagination.Prev
              className="bgc larrow"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </Pagination.Prev>
            <Pagination.Item disabled>
              {`Page: ${currentPage} of ${totalPages}`}
            </Pagination.Item>
            <Pagination.Next
              className="bgc rarrow"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </Pagination.Next>
            <Pagination.Last
              className="bgc rddarrow"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardDoubleArrowRight />
            </Pagination.Last>
          </Pagination>
          <Form.Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="rowperpage"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
      <div className="parent_table">
        <div className="table-container">
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ width: GridWidth + "%" }}
          >
            <thead>
              <tr className="tb-heading">
                <th className="col-sm-1">Actions</th>
                <th className="thcol">S.No</th>
                {tableColumns.map((col, index) => (
                  <th key={index} style={{ width: col.width + "px" }}>
                    {col.label}
                  </th>
                ))}
              </tr>
              <tr className="tb-heading">
                <th></th>
                <th></th>
                {tableColumns.map((col, index) => (
                  <th key={index}>
                    <input
                      type="text"
                      className={`table-filter`}
                      style={{ width: "100%" }}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((row, rowIndex) => (
                  <tr className="tb-sub" key={rowIndex}>
                    <td>
                      <div className="tdcol">
                        <Button
                          className="bn"
                          onClick={() => openUpdateModal(row)}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          className="bn"
                          onClick={() =>
                            deleteDataFromTable(row[PrimaryKeyField])
                          }
                        >
                          <MdDelete />
                        </Button>
                      </div>
                    </td>
                    <td>{indexOfFirstEntry + rowIndex + 1}</td>
                    {tableColumns.map((col, colIndex) => (
                      <td
                        key={colIndex}
                        style={{ width: col.width + "px", overflow: "hidden" }}
                      >
                        {row[col.field]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr className="tb-sub">
                  <td colSpan={tableColumns.length + 2} className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default IcdqGrid;