// Destination.jsx
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IcdqModal from "../../AppComponents/icdqmodal/icdqmodal";

const Destination = () => {

  const screenfields = [
    {
      caption: "Code",
      field: "code",
      type: "text",
      required: true,
      spacefield: 3
    }, 
    {
      caption: "Country",
      fieldtype: 'dropdown',
      field: "country_id",
      required: true,
      fieldwidth: 3,
      valuefield: 'id',
      displayfield: "country_name",
      endpoint: "countries",
    },
    {
      caption: "State",
      fieldtype: 'dropdown',
      field: "state_id",
      spacefield: 2,
      required: true,
      fieldwidth: 3,
      valuefield: 'id',
      displayfield: "state_name",
      dependentOn: "country_id",
      endpoint: "statesByCountry",
    },
    {
      caption: "City",
      fieldtype: 'dropdown',
      field: "city_id",
      required: true,
      fieldwidth: 3,
      valuefield: 'id',
      displayfield: "city_name",
      dependentOn: "state_id",
      endpoint: "citiesByState",
    },
    {
      caption: "District",
      fieldtype: 'dropdown',
      field: "districtid",
      spacefield: 2,
      required: true,
      fieldwidth: 3,
      valuefield: 'id',
      displayfield: "district",
      dependentOn: "state_id",
      endpoint: "districtByState",
    },
    {
      caption: "Destination",
      field: "destination",
      spacefield: 2,
      type: "text",
      required: true,
      fieldwidth: 6,
    }
  ];

  const tableColumns = [
    { field: 'code', label: 'Code', width: 20 },
    { field: 'destination', label: 'Destination', width: 100 },
    { field: 'district', label: 'District', width: 100 }
  ];

  return (
    <IcdqModal title="Destination" GridWidth="45" FieldsArr={screenfields} controller="destination" PrimaryKeyField='id' GridColumns={tableColumns}/>
  );
};

export default Destination;
