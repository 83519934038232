import React, { useEffect, useState } from "react";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { urlApi } from "../../App";
import { Link, useLocation } from "react-router-dom";
import img2 from "./Slogo.png";
import userDefaultImage from "../../../src/user.png";

function NavigationMenu({ userInput, onLogout, SideBarValue, onLinkClick }) {
  const [menuItems, setMenuItems] = useState([]);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [isProfileBox, setIsProfileBox] = useState(false);
  const currentRoute = useLocation();
  const [loginUserImage, setLoginUserImage] = useState("");

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    SideBarValue(isNavOpen);
  };

  const userData = localStorage.getItem("userData");
  const userName = userData ? JSON.parse(userData) : "";

  useEffect(() => {
    let LoginUserImage = localStorage.getItem("profImg");
    setLoginUserImage(
      LoginUserImage != null ? LoginUserImage : userDefaultImage
    );
    const storedMenuItems = localStorage.getItem("menuItems");
    const storedActiveSubmenu = localStorage.getItem("activeSubmenu");

    if (storedActiveSubmenu) {
      setActiveSubmenu(storedActiveSubmenu);
    }
    if (storedMenuItems) {
      setMenuItems(JSON.parse(storedMenuItems));
    } else {
      const apiUrl = `${urlApi}/api/v1/userright/${userInput}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          data.sort((a, b) => a.seqno - b.seqno);

          const submenuItems = {};

          data.forEach((item) => {
            if (!submenuItems[item.modulename]) {
              submenuItems[item.modulename] = {
                moduleroute: item.moduleroute,
                moduleicon: item.moduleicon,
                items: [],
              };
            }

            submenuItems[item.modulename].items.push({
              screenname: item.screenname,
              screenroutes: item.screenroutes,
            });
          });
          setMenuItems(submenuItems);
          localStorage.setItem("menuItems", JSON.stringify(submenuItems));
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [userInput]);

  const handleArrowClick = (modulename) => {
    const newActiveSubmenu = activeSubmenu === modulename ? null : modulename;
    setActiveSubmenu(newActiveSubmenu);
    localStorage.setItem("activeSubmenu", newActiveSubmenu);
  };

  const showProfileBox = () => {
    setIsProfileBox(!isProfileBox);
  };

  const LinkClickFunc = (screenname) => {
    onLinkClick(screenname);
  };

  return (
    <>
      <div>
        <div id="navbar" className={isNavOpen ? "open" : "closed"}>
          <div className="logo-box">
            <div className="header-img">
              <img src={img2} alt="" />
            </div>
            <div className="headersidbar">
              <p>Swift Pass</p>
            </div>
          </div>
          <div className="sidebar side-menu">
            <ul id="navigation-menu" className="nav-links">
              {Object.keys(menuItems).map((modulename) => (
                <li
                  key={modulename}
                  className={
                    activeSubmenu === modulename ? "active-submenu" : ""
                  }
                >
                  <div
                    className={
                      activeSubmenu === modulename
                        ? "active-menu-class icon-link modulemain"
                        : "icon-link modulemain"
                    }
                    onClick={() => handleArrowClick(modulename)}
                    style={{
                      cursor:
                        activeSubmenu === modulename
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <div>
                      <a href="#">
                        <i
                          className={
                            menuItems[modulename].moduleicon +
                            " sidebar-icon-class"
                          }
                        ></i>
                        <span
                          className={`link_name ${
                            activeSubmenu === modulename ? "up" : "down"
                          }`}
                        >
                          {modulename}
                        </span>
                      </a>
                    </div>
                    <div className="arrow-icons">
                      {activeSubmenu === modulename ? (
                        <BsCaretUpFill />
                      ) : (
                        <BsCaretDownFill />
                      )}
                    </div>
                  </div>
                  {activeSubmenu === modulename &&
                    menuItems[modulename].items.length > 0 && (
                      <ul className="sub-menu">
                        {menuItems[modulename].items.map((submenuItem) => (
                          <li key={submenuItem.screenname}>
                            <Link
                              onClick={() =>
                                LinkClickFunc(submenuItem.screenname)
                              }
                              to={
                                "/" +
                                menuItems[modulename].moduleroute +
                                submenuItem.screenroutes
                              }
                              className={
                                currentRoute.pathname ===
                                "/" +
                                  menuItems[modulename].moduleroute +
                                  submenuItem.screenroutes
                                  ? "active-submenu-li"
                                  : ""
                              }
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                              {submenuItem.screenname}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
          </div>
          <div className="footer">
            <div className="footer"></div>
          </div>
        </div>
      </div>
      <div id="main" className={isNavOpen ? "content-open" : "content-closed"}>
        <p className="menu-icon">
          <div className="top-bar-left-side">
            <i className="fa-solid fa-bars-staggered" onClick={toggleNav}></i>
            <span className="heading">Industries And Commerce Department</span>
          </div>
          <div className="user-info" onClick={showProfileBox}>
            <div className="user-info-box">
              <div className="user-img">
                <img
                  style={{ borderRadius: "50%" }}
                  src={loginUserImage}
                  alt=""
                />
              </div>
              <div className="user-ino-txt">
                <h6 style={{ color: "#ca661d" }}> {userName} </h6>
                <h6>Admin</h6>
              </div>
            </div>
            <div className="angle-down-icon-user">
              <i className="fa fa-angle-down"></i>
            </div>
          </div>
          <div
            className="profile-box"
            style={{ display: isProfileBox ? "block" : "none" }}
          >
            <div className="header-prof">Profile</div>
            <div className="profile-box-information">
              <div className="profile-image">
                <img
                  src={loginUserImage}
                  style={{ width: "100%", borderRadius: "50%", height: "100%" }}
                  alt=""
                />
              </div>
              <div className="profie-box-info">
                <div className="username">
                  <span style={{ color: "#ca661d" }}>Username:</span>{" "}
                  {userName}
                </div>
                <button className="logoutbutton" onClick={onLogout}>
                  <span className="fa-solid fa-right-from-bracket"></span>{" "}
                  Logout
                </button>
              </div>
            </div>
          </div>
        </p>
      </div>
    </>
  );
}

export default NavigationMenu;
