// Designation.jsx
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IcdqModal from "../../AppComponents/icdqmodal/icdqmodal";

const Designation = () => {

  const screenfields = [
    {
        caption: "Code",
        field: "code",
        type: "text",
        required: true,
        spacefield: 3
    }, 
    {
        caption: "Designation",
        type: "text",
        field: "designation",
        spacefield: 2,
        required: true,
        fieldwidth: 6
    }
  ];


  const tableColumns = [
    { field: 'code', label: 'Code', width: 20 },
    { field: 'designation', label: 'Designation', width: 100 },
  ];

  return (
    <IcdqModal GridWidth="30" FieldsArr={screenfields} title="Designation" controller="designation" GridColumns={tableColumns} PrimaryKeyField="id" />
  );
};

export default Designation;
