import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IcdqModal from "../../AppComponents/icdqmodal/icdqmodal";

const Dealer = () => {
  const screenfields = [
    {
      caption: "Registration No.",
      field: "reg_no",
      type: "text",
      required: true,
      fieldwidth: 3,
    },
    {
      caption: "Registration Date",
      field: "reg_date",
      type: "date",
      required: true,
      fieldwidth: 3,
      spacefield: 2,
      currentdate: true,
    },
    {
      caption: "Dealer Name",
      field: "dealer_name",
      type: "text",
      required: true,
      spacefield: 2,
      fieldwidth: 6,
    },
    {
      caption: "Country",
      fieldtype: "dropdown",
      field: "countryid",
      fieldwidth: 3,
      valuefield: "id",
      displayfield: "country_name",
      endpoint: "countries",
      required: true,
    },
    {
      caption: "State",
      fieldtype: "dropdown",
      field: "stateid",
      fieldwidth: 3,
      valuefield: "id",
      displayfield: "state_name",
      dependentOn: "countryid", // Added dependentOn
      endpoint: "statesByCountry",
      required: true,
    },
    {
      caption: "City",
      fieldtype: "dropdown",
      field: "cityid",
      spacefield: 1,
      fieldwidth: 3,
      valuefield: "id",
      displayfield: "city_name",
      dependentOn: "stateid", // Added dependentOn
      endpoint: "citiesByState",
      required: true,
    },
    {
      caption: "CNIC",
      field: "cnic",
      type: "cnic",
      required: true,
      fieldwidth: 3,
    },
    {
      caption: "Contact No.",
      field: "contact_no",
      type: "tel",
      required: true,
      fieldwidth: 3,
    },
    {
      caption: "WhatsApp No.",
      field: "whatsapp_no",
      type: "tel",
      spacefield: 1,
      fieldwidth: 3,
    },
    {
      caption: "Address",
      field: "address",
      type: "textarea",
      fieldwidth: 6,
    },
    {
      caption: "Shop Address",
      field: "shopAddress",
      type: "textarea",
      fieldwidth: 6,
    },
  ];
  const tableColumns = [
    { field: "reg_no", label: "Reg. No.", width: 20 },
    { field: "reg_date", label: "Reg. Date", width: 75 },
    { field: "dealer_name", label: "Dealer Name", width: 150 },
    { field: "cnic", label: "CNIC", width: 100 },
    { field: "contact_no", label: "Contact No.", width: 80 },
    { field: "whatsapp_no", label: "WhatsApp No.", width: 80 },
    { field: "address", label: "Address", width: 180 },
  ];
  const ModalTabs = [
    {
      tabid: 1,
      title: "Other Detail",
      type: "fields",
      TabFields: [
        {
          caption: "NTN No",
          type: "text",
          field: "ntnNo",
          required: true,
          fieldwidth: 3,
        },
        {
          caption: "Pay Order No",
          type: "text",
          field: "payorderNo",
          required: true,
          spacefield: 2,
          fieldwidth: 3,
        },
        {
          caption: "Pay Order Amount",
          type: "number",
          field: "payorderAmount",
          required: true,
          fieldwidth: 3,
        },
        {
          caption: "Date of Pay",
          type: "date",
          field: "dateOfPay",
          required: true,
          spacefield: 2,
          fieldwidth: 3,
          currentdate: true,
        },
      ],
    },
    {
      tabid: 2,
      title: "District",
      type: "grid",
      endpoint: "districtStateWise",
      dependentOn: "stateid",
      TabGridColumns:[
        {fieldname: "Code", field: 'code', width: 140}, {fieldname: "District", field: 'district', width: 170}
      ]
    },
  ];
  return (
    <IcdqModal
      detail={true}
      printtype="dealer"
      detailtable="trn_district"
      showTabs={true}
      tabs={ModalTabs}
      GridWidth="100"
      FieldsArr={screenfields}
      title="Dealer"
      controller="dealer"
      GridColumns={tableColumns}
      PrimaryKeyField="id"
      print={true}
    />
  );
};
export default Dealer;